import React from 'react'

const Menu = ({ visible, onClose }) => {
	return(
		<div onClick={ typeof onClose === 'function' ? () => onClose() : null } className={ ['w-screen h-screen fixed top-0 left-0 z-[999]', visible ? 'inline-block xl:hidden' : 'hidden'].join(' ') } style={{
			backgroundColor: 'rgba(0, 0, 0, .3)',
			backdropFilter: 'blur(3px)'
		}}>
			<div onClick={ (event) => event.stopPropagation() } className='w-[90vw] h-screen absolute top-0 right-0 bg-orange pt-[23px] sm:pt-20 overflow-auto'>
				<a href='/' className='w-full h-auto relative float-left mb-16 '>
					<div className='w-full relative float-left h-auto text-center uppercase font-athelas uppercase font-bold tracking-widest leading-[24px] text-[20px] sm:text-xs mb-[3px] text-white'>
						<span>Puerto Bello</span>
					</div>
					<div className='text-[12px] relative float-left text-white leading-[14px] font-athelas xl:text-sm sm:text-[8px] sm:leading-[9px] text-center uppercase w-full h-auto'>
						<span>Tequila</span>
					</div>
				</a>
				<a href='/process' className='w-full px-5 h-[40px] relative float-left text-[16px] mb-5 tracking-[3px] font-quicksand font-bold uppercase leading-[40px] text-white text-center'>
					<span>Process</span>
				</a>
				<a href='/our-tequila' className='w-full px-5 h-[40px] relative float-left text-[16px] mb-5 tracking-[3px] font-quicksand font-bold uppercase leading-[40px] text-white text-center'>
					<span>Our tequilas</span>
				</a>
				<a href='/cocktail' className='w-full px-5 h-[40px] relative float-left text-[16px] mb-5 tracking-[3px] font-quicksand font-bold uppercase leading-[40px] text-white text-center'>
					<span>Cocktails</span>
				</a>
				<a href='/contact-us' className='w-full px-5 h-[40px] relative float-left text-[16px] mb-20 tracking-[3px] font-quicksand font-bold uppercase leading-[40px] text-white text-center'>
					<span>Contact us</span>
				</a>
				<button className='w-[23.59px] h-[23.59px] sm:h-[26px] sm:w-[26px] absolute sm:top-20 right-[31.5px] top-[30px] sm:right-[5rem] flex-1 align-center xl:invisible xl:hidden' onClick={ typeof onClose === 'function' ? () => onClose() : null }>
					<div className='w-full h-[3px] absolute top-2/4 mt-[-1.5px] left-0 rounded-[1.5px] rotate-45 bg-white'></div>
					<div className='w-full h-[3px] absolute top-2/4 mt-[-1.5px] left-0 rounded-[1.5px] -rotate-45 bg-white'></div>
				</button>
			</div>
		</div>
	)
}

export default Menu