import React, { useState,useEffect } from "react";
import { Nav, CocktailSection, Footer, Menu } from "@components";
import { MixesWithBlanco, MixesWithReposado } from "../constants/mixes";
import CocktailSectionNew from "../components/CocktailSectionNew";
import AOS from "aos";
import "aos/dist/aos.css";

const HeadSection = () => (
  <div className="bgTextura_verde">
    <div className="relative flex flex-col justify-center cocktail-header bg-cocktail-header align-center">
      <div className="mx-auto h-auto xs:w-5/6 sm:w-2/5 text-center mt-6 mainsail-calm xs:text-4lg sm:text-4lg lg:text-7xl lg:w-3/6 xl:w-2/5 2xl:text-hero tituloCocktail" data-aos="fade-in"  data-aos-duration="2000">It Tastes Better<br/>With The Right Mix</div>
    </div>
  </div>
)

const Cocktail = () => {

  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuToggle = () => {
		setMenuOpen(menuOpen ? false : true)
	}

  useEffect(() => {
    document.title = "Tequila Puerto Bello - Cocktails";
    AOS.init({
      // duration : 5000
    });
  }, []);
  return (
    <div className="overflow-x-hidden text-white bg-cocktail-green">
      <Nav onMenu={ () => handleMenuToggle() } color="text-white"/>
      <HeadSection />
      <BlancoHeader />
      <BlancoSection />
      <ReposadoHeader />
      <ReposadoSection />
      <Footer />
      <Menu visible={ menuOpen } onClose={ () => handleMenuToggle() } />
    </div>
  );
};

const BlancoHeader = () => (
  <div className="relative mx-auto bg-cocktail-blue sm:-mb-32 md:-mb-40 h-auto">
    <img src="/images/Enmascarar downchain blue.png" alt="downchain blue" style={{filter: "brightness(50)"}}/>
    <span className="absolute bottom-0 left-0 w-full h-full radial-gradient-blue"></span>
    <div className="absolute left-1/3 top-10 2xl:top-10 h-1/2 w-1/3 2xl:p-20 text-center sm:text-lg md:text-lg lg:text-1xl xl:text-2xl 2xl:text-2xl mainsail-calm uppercase">Mixes with blanco </div>
  </div>
)
const BlancoSection = () => {
  const [currentWithBlancoStep, setCurrentWithBlancoStep] = useState("Mojito"); // Estos son para identificar en que Mix esta posicionado el cliente y mostrar la información en base a eso

  function handleProduct(value) {
		setCurrentWithBlancoStep(value);
	}

  const mixesBlanco = MixesWithBlanco.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.link.current]: (
        <CocktailSectionNew
          mix={curr}
          handleClick={handleProduct}
          className="text-white bg-cocktail-blue"
          maxWidth="max-w-5xl"
          textColor="text-cocktail-bisque"
          sliderColor="bg-slider1"
        />
      ),
    }),
    {}
  );
  return (
    <div className="bg-cocktail-blue sm:pt-20 sm:pl-8 md:pl-12 lg:pl-16 xl:pl-20 2xl:pl-36">
      <div className="xs:hidden sm:block">
        {mixesBlanco[currentWithBlancoStep]}
      </div>
      <div className="xs:px-7 xs:block sm:hidden">
        {
          MixesWithBlanco.map((val, index) => {
            return (
              <div key={`${index}`}>
                { mixesBlanco[(val.link.current)]}
              </div>
            )
          })
        }
      </div>
    </div>
    
  )
}

const ReposadoHeader = () => ( 
  <div className="bg-orange text-center">
    <div className="w-auto h-auto mx-auto text-center sm:text-lg md:text-lg lg:text-1xl xl:text-2xl 2xl:text-2xl mainsail-calm uppercase pt-24"> Mixes with<br />Reposado </div>
  </div>
)

const ReposadoSection = () => {
  const [currentWithReposadoStep, setCurrentWithReposadoStep] = useState("Margarita"); // estos son para identificar en que Mix esta posicionado el cliente y mostrar la informacion enbase a eso

  function handleProduct(value) {
		setCurrentWithReposadoStep(value);
	}

  const mixesReposado = MixesWithReposado.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.link.current]: (
        <CocktailSectionNew
          mix={curr}
          handleClick={handleProduct}
          className="text-white bg-cocktail-bisque"
          maxWidth="max-w-5xl"
          textColor="text-cocktail-green"
          mask="bisque"
          sliderColor="bg-slider2"
        />
      ),
    }),
    {}
  );
  return (
    <div className="bg-cocktail-bisque sm:pt-20 sm:pl-8 md:pl-12 lg:pl-16 xl:pl-20 2xl:pl-36">
      <div className="xs:hidden sm:block">
        {mixesReposado[currentWithReposadoStep]}
      </div>
      <div className="xs:px-7 xs:block sm:hidden">
        {
          MixesWithReposado.map((val, index) => {
            return (
              <div key={`${index}`}>
                { mixesReposado[(val.link.current)]}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Cocktail;
