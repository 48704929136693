import React, { useEffect, useState } from "react";
import { Footer, Nav, Menu } from "@components";
import AOS from "aos";
import "aos/dist/aos.css";
import "@/fixcss.css";

const CraftPuertoBello = () => (
  <div className="relative flex flex-col justify-center craft-puerto-bello bg-craft-puerto-bello align-center">
    <div className="mx-auto xs:w-5/6 xs:text-4lg sm:text-4lg lg:text-7xl sm:w-1/3 lg:w-3/6 xl:w-1/3 text-center mt-6 mainsail-calm text-2xl 2xl:text-hero" data-aos="fade-in" data-aos-duration="2000">The Craft of Puerto Bello</div>
  </div>
);

const DownChain = () => (
  <div className="downchain-effect relative h-auto xs:-mb-20 sm:-mb-24 md:-mb-60 lg:-mb-64 2xl:-mb-60 aro_process">
    <span className="absolute bottom-0 h-full radial-gradient-green w-full"></span>
    <img src="/images/Enmascarar grupo 17.png" alt="" />
  </div>
)

const Description = () => (
  <div className="description-wrapper xs:w-full xs:px-8 md:w-2/3 md:mx-auto md:px-0 md:mt-28 2xl:-mt-20 relative">
    <div className="description w-5/5 2xl:w-2/3">
      <div className="uppercase font-mainsailslab xs:text-custom4sm md:text-xl md:tracking-wider 2xl:text-2xl 2xl:tracking-0wider tituloProcess" data-aos="fade-in" data-aos-duration="3000">
        We take care of every detail and make sure that each bottle carries our culture.
      </div>
      <div className="font-quicksand w-4/5 tracking-1widest mt-10 xs:text-custom1xs xs:w-full xs:mt-7 md:text-base 2xl:mt-5 2xl:pr-16 texto-parrafos" data-aos="fade-in" data-aos-duration="3000">
        Tequila is made from the plant Agave Azul and it can only be called like this if it grows in the state of Jalisco and some of the surrounding areas.
      </div>
    </div>
  </div>
)

const FirstSection = () => (
  <div className="first-section w-2/3 mx-auto flex flex-wrap xs:items-end xs:w-full xs:px-8 xs:mt-7 sm:items-start md:w-full md:pl-15 md:pr-14 md:mt-11 2xl:pl-40 2xl:pr-36">
    <div className="xs:flex-1 xs:order-1 sm:flex-2">
      <div className="flex xs:justify-start sm:justify-center">
        <img src="/images/Grupo 775.png" alt="Chain" className="w-full xs:w-[94px] md:w-[74px] lg:w-[98px] 2xl:w-[184px]" data-aos="fade-down" data-aos-duration="3000" data-aos-delay="50" />
      </div>
    </div>
    <div className="flex-2 xs:order-2" data-aos="fade-in" data-aos-duration="3000">
      <div className="md:pl-1 md:mt-28 lg:mt-36 2xl:mt-64">
        <div className="font-quicksand xs:text-custom1xs sm:hidden">01</div>
        <div className="font-turquoise sm:hidden font-normal xs:text-custom5sm">Land</div>
      </div>
      <div className="flex justify-start sm:mt-28 md:mt-28 lg:mt-36 2xl:mt-64">
        <img src="/images/Agave 2.png" alt="Agave" className="w-full xs:w-[205px] md:w-[164px] lg:w-[219px] 2xl:w-[409px]" />
      </div>
    </div>
    <div className="xs:flex-auto xs:text-right xs:order-3 sm:flex-2 sm:text-left" data-aos="fade-in" data-aos-duration="3000"> 
      <div className="md:pl-1 md:mt-28 lg:mt-36 2xl:mt-64">
        <div className="font-quicksand xs:hidden xs:text-custom1xs sm:block md:text-2xs md:tracking-1wider md:pb-1 lg:text-custom3sm 2xl:pb-3 2xl:text-base">01</div>
        <div className="font-turquoise font-normal xs:hidden xs:text-custom5sm sm:block md:text-custom2sm lg:text-lg 2xl:text-custom1xl">Land</div>
        <div className="font-quicksand font-medium pt-8 xs:text-custom1xs md:text-custom1sm md:tracking-2wider md:pt-4 2xl:text-base 2xl:pt-10 texto-parrafos">
          The red and semi-arid land of Los Altos de Jalisco is rich in minerals, perfect for the growth of a plant with a much more fruity taste from which Puerto Bello Tequila is extracted. 
        </div>
      </div>
    </div>
    
  </div>
)

const SecondSection = () => (
  <div className="second-section w-2/3 mx-auto flex flex-wrap xs:items-end xs:w-full xs:px-8 xs:mt-10 sm:items-start md:w-full md:pl-15 md:pr-14 md:mt-11 2xl:pl-40 2xl:pr-36 2xl:mt-28">
    <div className="flex-2 xs:order-1" data-aos="fade-in" data-aos-duration="3000">
      <div className="font-quicksand xs:text-custom1xs sm:hidden">02</div>
      <div className="font-turquoise sm:hidden font-normal xs:text-custom5sm">Jima</div>
      <div className="flex justify-center md:mt-20 lg:mt-24 2xl:mt-48">
        <img src="/images/coa.png" alt="Coa" className="w-full xs:w-[218px] md:w-[188px] lg:w-[250px] 2xl:w-[467px]" />
      </div>
    </div>
    <div className="xs:flex-auto xs:order-3 sm:flex-2 sm:order-2" data-aos="fade-in" data-aos-duration="3000"> 
      <div className="md:pr-12 md:mt-6 lg:mt-10 2xl:mt-20">
        <div className="font-quicksand xs:hidden xs:text-custom1xs sm:block md:text-2xs md:tracking-1wider md:pb-1 lg:text-custom3sm 2xl:pb-3 2xl:text-base">02</div>
        <div className="font-turquoise font-normal xs:hidden xs:text-custom5sm  sm:block md:text-custom2sm lg:text-lg 2xl:text-custom1xl">Jima</div>
        <div className="font-quicksand font-medium pt-8 xs:text-custom1xs md:text-custom1sm md:tracking-2wider md:pt-4 2xl:text-base 2xl:pt-10 texto-parrafos">
          The process of harvesting the agave pineapples once the plant is fully mature. The “Jimador” is a person who dedicates his life to the agave plants. To harvest, he uses the “coa”, a long-handled cutting instrument. He removes the stalks and cuts the root to extract it from the ground. The first jima is eight or ten years after it has been planted.
        </div>
      </div>
    </div>
    <div className="flex-1 xs:order-2 sm:order-3" data-aos="fade-down" data-aos-duration="3000" data-aos-delay="50">
      <div className="flex xs:justify-end sm:justify-start">
        <img src="/images/Grupo 759.png" alt="Chain" className="w-full xs:w-[82px] md:w-[67px] lg:w-[88px] 2xl:w-[165px]" />
      </div>
    </div>
  </div>
)

const ThirdSection = () => (
  <div className="third-section w-2/3 mx-auto flex flex-wrap xs:items-end xs:w-full xs:px-8 xs:mt-10 sm:items-start md:w-full md:pl-15 md:pr-14 md:mt-11 2xl:pl-40 2xl:pr-36 2xl:mt-0">
    <div className="flex-2 xs:order-1 xs:pb-9 sm:pb-0" data-aos="fade-down" data-aos-duration="3000" data-aos-delay="50">
      <div className="flex xs:justify-start sm:justify-center">
        <img src="/images/Grupo 776.png" alt="Chain" className="w-full xs:w-[94px] md:w-[74px] lg:w-[98px] 2xl:w-[184px]" />
      </div>
    </div>
    <div className="flex-2 xs:order-2" data-aos="fade-in" data-aos-duration="3000">
      <div className="font-quicksand xs:text-custom1xs sm:hidden xs:text-right">03</div>
      <div className="font-turquoise sm:hidden font-normal xs:text-custom5sm xs:text-right xs:pb-5">Cooking</div>
      <div className="flex xs:justify-center sm:justify-start md:mt-16 md:pl-6 lg:mt-20 2xl:mt-28">
        <img src="/images/Cooking.png" alt="Cooking" className="w-full xs:w-[100px] md:w-[115px] lg:w-[153px] 2xl:w-[285px]" />
      </div>
    </div>
    <div className="xs:flex-auto xs:order-3 xs:text-right sm:flex-2 sm:text-left" data-aos="fade-in" data-aos-duration="3000"> 
      <div className="md:pl-1 md:mt-14 lg:mt-16 2xl:mt-36">
        <div className="font-quicksand xs:hidden xs:text-custom1xs sm:block md:text-2xs md:tracking-1wider md:pb-1 lg:text-custom3sm 2xl:pb-3 2xl:text-base">03</div>
        <div className="font-turquoise font-normal xs:hidden xs:text-custom5sm  sm:block md:text-custom2sm lg:text-lg 2xl:text-custom1xl">Cooking</div>
        <div className="font-quicksand font-medium pt-8 xs:text-custom1xs md:text-custom1sm md:tracking-2wider md:pt-4 2xl:text-base 2xl:pt-10 texto-parrafos">
          Cooking the agave is one of the essential parts. We cook the “piñas” inside the ovens or “autoclaves” for up to 18 hours to convert starches into sugars that will be later occupied in the fermentation process. Then, the agaves are left to cool for nine hours.
        </div>
      </div>
    </div>
  </div>
)

const FourthSection = () => (
  <div className="fourth-section w-2/3 mx-auto flex flex-wrap xs:items-end xs:w-full xs:px-8 xs:mt-10 sm:items-start md:w-full md:pl-15 md:pr-14 md:mt-11 2xl:pl-40 2xl:pr-36 2xl:mt-28">
    <div className="flex-2 xs:order-1" data-aos="fade-in" data-aos-duration="3000">
      <div className="font-quicksand xs:text-custom1xs sm:hidden">04</div>
      <div className="font-turquoise sm:hidden font-normal xs:text-custom5sm xs:pb-5">Mills</div>
      <div className="flex justify-center md:mt-16 md:pl-6 2xl:mt-28 xl:pl-0">
        <img src="/images/Mills.png" alt="Mills" className="w-full xs:w-[101px] md:w-[114px] lg:w-[152px] 2xl:w-[284px]" />
      </div>
    </div>
    <div className="xs:flex-auto xs:order-3 sm:flex-2 sm:order-2" data-aos="fade-in" data-aos-duration="3000"> 
      <div className="md:pr-12 md:mt-6 lg:mt-4 2xl:mt-22">
        <div className="font-quicksand xs:hidden xs:text-custom1xs sm:block md:text-2xs md:tracking-1wider md:pb-1 lg:text-custom3sm 2xl:pb-3 2xl:text-base">04</div>
        <div className="font-turquoise font-normal xs:hidden xs:text-custom5sm  sm:block md:text-custom2sm lg:text-lg 2xl:text-custom1xl">Mills</div>
        <div className="font-quicksand font-medium pt-8 xs:text-custom1xs md:text-custom1sm md:tracking-2wider md:pt-4 2xl:text-base 2xl:pt-10 texto-parrafos">
          We work with the most innovative technology and without leaving behind the traditional way of making Tequila. The mills destroy the agave and crush the “piñas” to extract the juices. The heart of the agave and the stalks have high content of fructose and other vitamins, in addition to fat particles that give that special taste and smell. From the center of the pineapple we get the natural juice that is made up of fibers, mineral salts, water and sugar.
        </div>
      </div>
    </div>
    <div className="xs:flex-2 xs:order-2 sm:flex-1 sm:order-3 xs:pb-9 sm:pb-0" data-aos="fade-down" data-aos-duration="3000" data-aos-delay="50">
      <div className="flex justify-start">
        <img src="/images/Grupo 759.png" alt="Chain" className="w-full xs:w-[86px] md:w-[67px] lg:w-[88px] 2xl:w-[165px]" />
      </div>
    </div>
  </div>
)

const FifthSection = () => (
  <div className="fifth-section w-2/3 mx-auto flex flex-wrap xs:items-end xs:w-full xs:px-8 xs:mt-10 sm:items-start md:w-full md:pl-15 md:pr-14 md:mt-11 2xl:pl-40 2xl:pr-36 2xl:mt-0">
    <div className="flex-2 xs:order-1 xs:pb-36 sm:pb-0" data-aos="fade-down" data-aos-duration="3000" data-aos-delay="50">
      <div className="flex xs:justify-start sm:justify-center">
        <img src="/images/Grupo 756.png" alt="Chain" className="w-full xs:w-[97px] md:w-[74px] lg:w-[98px] 2xl:w-[184px]" />
      </div>
    </div>
    <div className="flex-2 xs:order-2" data-aos="fade-in" data-aos-duration="3000">
      <div className="font-quicksand xs:text-custom1xs xs:text-right sm:hidden">05</div>
      <div className="font-turquoise sm:hidden font-normal xs:text-custom5sm xs:pb-5 xs:text-right">Fermentation</div>
      <div className="flex justify-start md:mt-16 md:pl-10 lg:mt-20 2xl:mt-24 2xl:pl-24">
        <img src="/images/Fermantation.png" alt="Fermantation" className="w-full xs:w-[107px] md:w-[74px] lg:w-[98px] 2xl:w-[184px]" />
      </div>
    </div>
    <div className="xs:flex-auto xs:order-3 xs:text-right sm:flex-2 sm:text-left" data-aos="fade-in" data-aos-duration="3000"> 
      <div className="md:pl-1 md:mt-14 2xl:mt-32">
        <div className="font-quicksand xs:hidden xs:text-custom1xs sm:block md:text-2xs md:tracking-1wider md:pb-1 lg:text-custom3sm 2xl:pb-3 2xl:text-base">05</div>
        <div className="font-turquoise font-normal xs:hidden xs:text-custom5sm  sm:block md:text-custom2sm lg:text-lg 2xl:text-custom1xl">Fermentation</div>
        <div className="font-quicksand font-medium pt-8 xs:text-custom1xs md:text-custom1sm md:tracking-2wider md:pt-4 2xl:text-base 2xl:pt-10 texto-parrafos">
          It lasts between 24 to 30 hours in stainless steel tanks. We play classical music while the mix rests and we call it the Mozart Method. The final product is the sugar conversion into alcohol. This process is one of the main distinctions of our Puerto Bello.
        </div>
      </div>
    </div>
  </div>
)

const SixthSection = () => (
  <div className="sixth-section w-2/3 mx-auto flex flex-wrap xs:items-end xs:w-full xs:px-8 xs:mt-10 sm:items-start md:w-full md:pl-15 md:pr-14 md:mt-11 2xl:pl-40 2xl:pr-36 2xl:mt-24">
    <div className="flex-2 xs:order-1" data-aos="fade-in" data-aos-duration="3000">
      <div className="font-quicksand xs:text-custom1xs sm:hidden">06</div>
      <div className="font-turquoise sm:hidden font-normal xs:text-custom5sm xs:pb-5">Distillation</div>
      <div className="flex justify-center md:mt-24 md:pl-6 lg:mt-44 2xl:mt-14">
        <img src="/images/Distillation.png" alt="Distillation" className="w-full xs:w-[192px] md:w-[115px] lg:w-[220px] 2xl:w-[412px]" />
      </div>
    </div>
    <div className="xs:flex-auto xs:order-3 sm:flex-2 sm:order-2" data-aos="fade-in" data-aos-duration="3000"> 
      <div className="md:pr-12 md:mt-6 lg:mt-12 2xl:mt-24">
        <div className="font-quicksand xs:hidden xs:text-custom1xs sm:block md:text-2xs md:tracking-1wider md:pb-1 lg:text-custom3sm 2xl:pb-3 2xl:text-base">06</div>
        <div className="font-turquoise font-normal xs:hidden xs:text-custom5sm  sm:block md:text-custom2sm lg:text-lg 2xl:text-custom1xl">Distillation</div>
        <div className="font-quicksand font-medium pt-8 xs:text-custom1xs md:text-custom1sm md:tracking-2wider md:pt-4 2xl:text-base 2xl:pt-10 texto-parrafos">
          Distilled in stills, this is a two-step process. At the end of the first distillation, we obtain what is known as ordinary tequila. Once the second step ends, the result is our Puerto Bello Tequila Blanco.
        </div>
      </div>
    </div>
    <div className="flex-1 xs:order-2 xs:pb-24 sm:pb-0" data-aos="fade-down" data-aos-duration="3000" data-aos-delay="50">
      <div className="flex xs:justify-end sm:justify-start">
        <img src="/images/Grupo 759.png" alt="Chain" className="w-full xs:w-[87px] md:w-[67px] lg:w-[88px] 2xl:w-[165px]" />
      </div>
    </div>
  </div>
)

const SeventhSection = () => (
  <div className="bg-seventh-section w-2/3 mx-auto flex flex-wrap xs:items-end xs:w-full xs:px-8 xs:mt-10 sm:items-start md:w-full md:pl-15 md:pr-14 md:mt-11 2xl:pl-40 2xl:pr-36 2xl:mt-28">
    <div className="xs:flex-1 xs:order-1 sm:flex-2 xs:pb-16 sm:pb-0" data-aos="fade-down" data-aos-duration="3000" data-aos-delay="50">
      <div className="flex xs:justify-start sm:justify-center">
        <img src="/images/Grupo 756.png" alt="Chain" className="w-full xs:w-[97px] md:w-[74px] lg:w-[98px] 2xl:w-[184px]" />
      </div>
    </div>
    <div className="flex-2 xs:order-2" data-aos="fade-in" data-aos-duration="3000">
      <div className="font-quicksand xs:text-custom1xs sm:hidden">07</div>
      <div className="font-turquoise sm:hidden font-normal xs:text-custom5sm xs:pb-5">Barreling</div>
      <div className="flex xs:justify-center sm:justify-start md:mt-60 md:pl-5 lg:mt-72 2xl:mt-16">
        <img src="/images/Barreling.png" alt="Barreling" className="w-full xs:w-[160px] md:w-[130px] lg:w-[200px] 2xl:w-[380px]" />
      </div>
    </div>
    <div className="xs:flex-auto xs:text-right xs:order-3 sm:flex-2 sm:text-left" data-aos="fade-in" data-aos-duration="3000"> 
      <div className="md:pl-1 md:mt-14 2xl:mt-20">
      <div className="font-quicksand xs:hidden xs:text-custom1xs sm:block md:text-2xs md:tracking-1wider md:pb-1 lg:text-custom3sm 2xl:pb-3 2xl:text-base">07</div>
        <div className="font-turquoise font-normal xs:hidden xs:text-custom5sm  sm:block md:text-custom2sm lg:text-lg 2xl:text-custom1xl">Barreling</div>
        <div className="font-quicksand font-medium pt-8 xs:text-custom1xs md:text-custom1sm md:tracking-2wider md:pt-4 2xl:text-base 2xl:pt-10 texto-parrafos">
          Blanco is served directly from the stills but the other two need to be aged. Puerto Bello Reposado is aged for 8 months, while Añejo is aged for 1.5 years. We age them in white american oak barrels. Our production line fills the bottles, while we do careful packaging by hand.
        </div>
      </div>
    </div>
  </div>
)

const ProcessBody = () => ( 
  <div className="ProcessBody bg-[#4CC1BB] bg-process-body bg-no-repeat bg-right-bottom xs:bg-contain xs:pb-20 md:pb-20 md:bg-contain lg:pb-36 2xl:pb-64 2xl:bg-auto">
    <DownChain />
    <Description />
    <FirstSection />
    <SecondSection />
    <ThirdSection />
    <FourthSection />
    <FifthSection />
    <SixthSection />
    <SeventhSection />
  </div>
)

const Process = () =>  {
  const [menuOpen, setMenuOpen] = useState(false);
  function handleMenuToggle() {
		setMenuOpen(menuOpen ? false : true)
	}
  useEffect(() => {
    document.title = "Tequila Puerto Bello - Process";
    AOS.init({
      // duration : 5000
    });
  }, []);
  return (
    <div className="bg-[#F97DC4]" style={{ color: " white" }}>
        <Nav onMenu={ () => handleMenuToggle() } color="text-white"/>
        <CraftPuertoBello />
        <ProcessBody />
        <Footer />
        <Menu visible={ menuOpen } onClose={ () => handleMenuToggle() } />
    </div>
  )
}

export default Process;
