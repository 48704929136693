import React, {useState, useEffect} from "react";
import { Footer, Nav, Menu } from "@components";

const Hero = () => (
	<div className='relative flex flex-col justify-center privacy-police bg-privacy-police align-center'>
    	<div className="mx-auto mt-6 mainsail-calm text-hero text-center">Terms &<br />Conditions</div>
	</div>
)

const Content = () => (
	<div className='p-48 w-full h-auto bg-[#80D8D0]'>
		<p className='text-[18px] font-medium quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Effective 20 July 2021</p>
		<p className='text-[18px] font-medium quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Please read carefully before using this site</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>RIGHTS</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>All the materials published, intellectual property and database rights are owned by us. All such rights are reserved. You agree that you are permitted to use this material only as set forth in and to the extent permitted by these Conditions of Use. You may not remove any copyright.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>RESTRICTIONS ON USE</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>You may not use our site except as expressly authorized by us, or by law, to reproduce, sell, adapt, translate, or to use it for commercial purposes.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>INTERDEPENDENT SERVICES</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Interdependent services on our site where we do provide any connected relation or activity will provide clear information to you about the kind of service offered. However we are not responsible directly or indirectly for any loss or damage arising from the use of these services by a user.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>UPLOADING MATERIAL BY YOU</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Unless specified, we do not solicit any confidential information from you to the site. Any material you upload to our site will be considered non-confidential and non-proprietary and we have the right to use it in whole or in parts. You warrant any moral rights have been waived by authors. We have the right to eliminate any material you make on our site.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>ACCESSING</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>We reserve the right to cancel the services we provide on our site without notice. You are responsible for your internet connection, as well as we will not be responsible for any loss or damage as a result of your failure to comply with this or any other part of these terms. You are responsible for having access to our site and are aware of these terms. Occasionally, we may restrict access to some parts of the site.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>LINKS TO & FROM SITES OF OUR PARTNERS</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Our site contains links to other sites and these are provided for your information only. We have no responsibility over those contents or damage caused from your use of them.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>INDEMNITY</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>You will indemnify us and our employees or representatives against any claims about information or material you have submitted or will submit to us that is in violation of any law or third party rights including information obtained from linked sites. We reserve the right to defend and control any claims arising from the above and any such indemnification matters, and you hereby agree to cooperate fully with us in any such defenses.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>SOCIAL RESPONSIBILITY</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>We are extremely proud of our sustainable policy in all areas of the product & company practices. As part of our commitment we ask your help to promote sustainability by returning the Puerto Bello bottles once these are empty and we will turn them into new products such as pots, decorations or candlesticks. You can send us a message if interested.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>ENTIRE AGREEMENT</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>These terms and conditions as well as the privacy policy constitute the entire agreement between you and us in relation to the use of our site. If any provision of the Terms & Conditions is found by a court of competent jurisdiction to be invalid, the parties agree that the court should endeavor to give effect to the parties’ intentions as reflected in that provision, and the other content of the Terms & Conditions shall remain in effect. We may transfer any or all of our rights and obligations under the Terms & Conditions to any third party or parties at any time.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>LAW AND JURISDICTION</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>If you are located in the U.S., these Terms & Conditions, including the Privacy Policy and any matter relating to the site, shall be governed with the laws of the State of Florida in the United States. If you are located anywhere except the U.S., then these Terms & Conditions, including the Privacy Policy and any matter relating to the site, are governed by Mexico; and Mexican courts will have exclusive jurisdiction over any claim.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>UPDATES AND CHANGES</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>We may revise these Terms & Conditions at any time. Each time you use the site, the current version will apply.</p>
		<p className='text-[18px] quicksand tracking-[1.8px] leading-[30px]'>2021 - All rights reserved.</p>
	</div>
)

const TermsAndConditions = () => {
	const [menuOpen, setMenuOpen] = useState(false);
  function handleMenuToggle() {
		setMenuOpen(menuOpen ? false : true)
	}

	useEffect(() => {
		document.title = "Tequila Puerto Bello - Terms and Conditions";
	}, []);

  return (
	<div style={{ backgroundColor: "#4CC1BB", color: " white" }}>
		<Nav onMenu={ () => handleMenuToggle() } color="text-white"/>
		<Hero />
		<Content />
		<Footer />
		<Menu visible={ menuOpen } onClose={ () => handleMenuToggle() } />
	</div>
  );
};

export default TermsAndConditions;