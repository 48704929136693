import React, {useEffect, useState} from "react";
import { Mask, Footer, Nav, Menu } from "@components";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from 'axios';


const ContactUsSection = () => (
  <div> </div>
)
const ImageSection = () => (
  <div className="small-section bg-pexels-cottonbro"> </div>
)

const ParalaxSection = () => (
  <div className="paralaxSection">
    
  </div>
);

class HeadSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }

  onNameChange(event) {
    this.setState({name: event.target.value})
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }

  resetForm(){
    this.setState({name: "", email: "", message: ""})
  }

  handleSubmit(event) {
    event.preventDefault();
    // console.log(this.state);
    let baseURL="https://pbtequila.marabunta.work";

    axios({
      method: "POST",
      url:baseURL+"/api/send_email.php",
      data:  this.state,
    }).then((response)=>{
      console.log(response.data);
      if (response.data.status === 'success') {
        alert("Thank you! \nWe’ve received your information and will contact you soon.");
        this.resetForm()
      } else if(response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }

  render (){
    return (
      <div className="m-auto w-2/3 pt-24 pb-48">
        <form method="POST" onSubmit={this.handleSubmit.bind(this)}>
          <div className="text-hero text-center my-5 mainsail-calm tituloContactUs"> Contact Us! </div>
          <div className="m-auto ml-0 text-xl flex my-20 mainsail-calm inputSection">
            <span className="xs:w-4/12 md:w-2/12 lg:w-3/12 xl:w-3/12 2xl:w-2/12">Name:{" "} </span>
            <input type="text" id="name" required value={this.state.name} onChange={this.onNameChange.bind(this)} onInvalid={(e) => {e.target.setCustomValidity("Please, enter the information required.")} } onInput={ (e) => {e.target.setCustomValidity('')}} className="xs:w-8/12 md:w-10/12 lg:w-9/12 xl:w-9/12 2xl:w-10/12 bg-transparent" style={{ borderBottom: "3px solid white" }} />
          </div>
          <div className="m-auto ml-0 text-xl my-20 flex mainsail-calm inputSection">
            <span className="xs:w-4/12 md:w-2/12 lg:w-3/12 xl:w-3/12 2xl:w-2/12">Email:{" "} </span>
            <input type="email" id="email" required value={this.state.email} onChange={this.onEmailChange.bind(this)} onInvalid={(e) => {e.target.setCustomValidity("Please, enter the information required." )} } onInput={ (e) => {e.target.setCustomValidity('')}} className="xs:w-8/12 md:w-10/12 lg:w-9/12 xl:w-9/12 2xl:w-10/12 bg-transparent" style={{ borderBottom: "3px solid white" }} />
          </div>
          <div className="m-auto ml-0 text-xl mt-20 flex items-center justify-start mainsail-calm inputSection">
            <span className="xs:w-4/12 md:w-2/12 lg:w-3/12 xl:w-3/12 2xl:w-2/12">Message:{" "} </span>
            <input type="text" id="message" required value={this.state.message} onChange={this.onMessageChange.bind(this)} onInvalid={(e) => {e.target.setCustomValidity("Please, enter the information required.")} } onInput={ (e) => {e.target.setCustomValidity('')}} className="xs:w-8/12 md:w-10/12 lg:w-9/12 xl:w-9/12 2xl:w-10/12 bg-transparent" style={{ borderBottom: "3px solid white" }} />
          </div>
          <div className="flex items-center justify-end">
            <div className="xs:w-full md:w-10/12 lg:w-9/12 xl:w-9/12 2xl:w-10/12 text-sm pt-10 quicksand font-medium tracking-wider parrafoContactUs">
              *Help us to promote sustainability by returning the empty bottles and we will turn them into new products such as pots, decorations or candlesticks. You can send us a message if interested and for each bottle of Puerto Bello tequila returned, we will grant you a discount coupon on your next purchase.
            </div>
          </div>
          <div className="text-right">
            {/* <div className="bg-[#80D8D0] ml-auto px-16 p-4 w-72 text-center text-xl mt-10 uppercase quicksand font-bold cursor-pointer" style={{ letterSpacing: "6px" }}> */}
            <button type="submit" className="btn bg-[#80D8D0] ml-auto px-16 p-4 w-72 text-center text-xl mt-10 uppercase quicksand font-bold cursor-pointer buttonSendContactUs" style={{ letterSpacing: "6px" }}>Send</button>
            {/* </div> */}
          </div>
        </form>
      </div>
    )
  }
}

const ContactUs = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  function handleMenuToggle() {
		setMenuOpen(menuOpen ? false : true)
	}
  useEffect(() => {
    document.title = "Tequila Puerto Bello - Contact Us";
    AOS.init({
      // duration : 5000
    });
  }, []);

  return(
    <div className="bg-extra-green text-white">
    <Nav onMenu={ () => handleMenuToggle() } color="text-white"/>
    <HeadSection />
    <ContactUsSection />
    <ParalaxSection />
    <Footer />
    <Menu visible={ menuOpen } onClose={ () => handleMenuToggle() } />
  </div>
  )
  
}

export default ContactUs;
