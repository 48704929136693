import React, { useEffect, useState } from "react";
import { Mask, Footer, Nav, Menu } from "@components";
import AOS from "aos";
import "aos/dist/aos.css";
import "@/fixcss.css";

const MasterTheMomentSection = () => (
  <div className="bgTextura_verde">
    <div className="relative flex flex-col justify-center master-the-moment bg-master-the-moment align-center" style={{backgroundImage:'/images/bg_home1.png'}}>
      <div className="mx-auto 2xl:w-[115px] lg:w-[62px] md:w-[54px] xs:w-[89px] h-auto" >
        <img src="/images/Trazado 1048.png" className="w-100 florMasterTheMoment" />
      </div>
      <div className="mx-auto mt-6 mainsail-calm md:text-3lg 2xl:text-hero textoMasterTheMoment" data-aos="fade-in"  data-aos-duration="2000">#MasterTheMoment</div>

      <div className="absolute trazado-superior-derecha">
        <img src="/images/Grupo 340.png" className="w-100" />
      </div>
      <div className="absolute trazado-inferior-derecha">
        <img src="/images/Grupo 340.png" className="w-100" />
      </div>
      <div className="absolute trazado-inferior-izquierda">
        <img src="/images/Grupo 340.png" className="w-100" />
      </div>
    </div>
  </div>
  
);

const AwardsSection = () => (
  <div
    className="relative 2xl:h-[1216px] lg:h-[682px] md:h-[500px] xs:h-[844px] xs:w-full text-center awards 2xl:bg-awards lg:bg-awards md:bg-awards-rotate xs:bg-awards-rotate-90 bg-cocktail-blue mainsail-calm"   
  >
    <div className="mx-auto relative top-2/4 translate-y-[-70%] w-full pointer-events-none" style={{display:'flex', justifyContent:'center', alignItems:"flex-start"}}>
      <img
        src="/images/Home big bottle.png"
        alt="Holy big bottle with tequila!"
        className="w-full h-auto 2xl:pt-[50%] md:pt-[50%] lg:pt-[55%] xs:pt-[55%]  object-contain botellaAwards"
        data-aos="fade-up"  data-aos-duration="2000"
        
      />
    </div>
    <div className="absolute uppercase 2xl:text-right lg:text-right md:text-right xs:text-center xs:text-3xs md:text-xs lg:text-2sm 2xl:text-2lg 2xl:w-[416px] lg:w-[222px] md:w-[165px] xs:w-[300px] 2xl:top-[304px] lg:top-[189px] md:top-[58px] xs:top-[109px] 2xl:left-[140px] lg:left-[74px] md:left-[68px] xs:left-[48px]"
    data-aos="fade-in">
      Bronze Brand of Promise 2019 by Tequila Aficionado
    </div>
    <div className="absolute uppercase xs:text-3xs md:text-xs  2xl:text-left lg:text-left md:text-left xs:text-center lg:text-2sm 2xl:text-2lg 2xl:w-[416px] lg:w-[222px] md:w-[165px] xs:w-[300px] 2xl:top-[304px] lg:top-[189px] md:top-[58px] xs:top-[40px] 2xl:right-[140px] lg:right-[74px] md:right-[68px] xs:right-[48px]"
    data-aos="fade-in">
      Silver Medal WSWA 2019 for Puerto Bello Añejo
    </div>
    <div className="absolute uppercase xs:text-3x md:text-xs 2xl:text-right lg:text-right md:text-right xs:text-center lg:text-2sm 2xl:text-2lg 2xl:w-[398px] lg:w-[213px] md:w-[200px] xs:w-[300px] 2xl:top-[638px] lg:top-[391px] md:top-[289px] xs:top-[268px] 2xl:left-[140px] lg:left-[74px] md:left-[21px] xs:left-[48px]"
    data-aos="fade-in">
      Silver Medal WSWA 2019 for Puerto Bello Reposado
    </div>
    <div className="absolute uppercase xs:text-3x md:text-xs 2xl:text-left lg:text-left md:text-left xs:text-center lg:text-2sm 2xl:text-2lg 2xl:w-[398px] lg:w-[213px] md:w-[200px] xs:w-[300px] 2xl:top-[638px] lg:top-[391px] md:top-[289px] xs:top-[189px] 2xl:right-[140px] lg:right-[74px] md:right-[21px] xs:right-[48px]"
    data-aos="fade-in">
      Platinum Brand of Promise 2019 by Tequila Aficionado
    </div>
    <div
      className="absolute uppercase w-full textBottomBottle"
      data-aos="fade-in" >
      Gold Medal WSWA 2019 for<br/>Puerto Bello Blanco
    </div>
      <div className="absolute trazado-inferior-izquierda tacheAwards">
        <img src="/images/Grupo 340.png" className="w-100" />
      </div>
  </div>
);

const SmallCactusSection = () => <div className="small-cactus 2xl:h-80 bg-small-cactus parallax md:h-52 xs:h-24"></div>;

const OurJourneySection = () => (
  
    <div className=" our-journey bg-our-journey">
      <div class="flex w-full justify-center flex-wrap md:bg-agave bg-no-repeat 2xl:bg-custom 2xl:bg-right-custom lg:bg-right-custom-lg lg:bg-custom-lg md:bg-right-custom-md md:bg-custom-md">
        <div className="xs:w-full xs:block md:hidden flex justify-center">
        <div class="uppercase text-center text-custom4sm w-full mainsail-calm pt-[15%] pb-[10%]"
          data-aos="fade-in"> Our journey </div>
        </div>
        <div className="xs:w-full xs:block md:hidden md:w-1/3 flex justify-center">
          <div class="bg-agave bg-no-repeat bg-contain h-[208px] w-full bg-center-custom"></div>
        </div>
        <div className="xs:w-full md:w-1/3 flex justify-center flex-col">
          <div
            class="2xl:text-6xl text-center xs:text-lg mainsail-calm md:pt-[40%] xs:py-[10%]"
            data-aos="fade-up"
            style={{ color: "#80D8D0"}}
            data-aos="fade-in">
            <span > The Plant </span>
          </div>
        </div>
        <div className="xs:w-full md:w-1/3 flex flex-col">
        <div class="xs:hidden md:block uppercase 2xl:my-22 lg:mt-36 md:text-center md:mt-[40%]  md:mb-16 lg:mb-16 w-full 2xl:text-21xl md:text-xl mainsail-calm"
        data-aos="fade-in"> Our journey </div>
          <div class="tracking-1widest 2xl:text-base md:text-4xs xs:text-custom1xs xs:px-[5%] md:text-left xs:text-center texto-parrafos" data-aos="fade-in">
            Tequila is a Mexican indigenous drink from the 16th century. It is obtained from the Blue
            agave tequilana Weber plant, better known as Agave Azul. Actually, thanks to protected
            designation of origin, Tequila can only be produced in Mexico in 5 states of the country.
          </div>
        </div>
        <div className="xs:w-full md:w-1/3 flex justify-center">
        
        </div>
        <div className="xs:w-full md:w-1/3 flex justify-center flex-col">
          <div
            class="xs:text-lg 2xl:text-6xl text-center lg:text-lg mainsail-calm lg:pt-[25%] xs:py-[10%]"
            data-aos="fade-up"
            style={{ color: "#80D8D0"}}
            data-aos="fade-in">
            <span > Founders </span>
          </div>
        </div>
        <div className="xs:w-full md:w-1/3 flex flex-col">
          <div class="tracking-1widest 2xl:text-base md:text-4xs md:mt-[30%] xs:text-custom1xs xs:px-[5%] md:text-left xs:text-center texto-parrafos" data-aos="fade-in">
          Puerto Bello is family owned and founded in Mexico from 100% Agave grown in Los Altos de
            Jalisco. This region has a land rich in minerals and due to the characteristics of its
            soil, agaves are the best of our days. Co-founders are twin brothers and their family is
            from Los Altos Jalisco, Mexico.
          </div>
        </div>
        <div className="xs:w-full md:w-1/3 flex justify-center">
        
        </div>
      </div>
      <div className="bgTextura_verde_textura-transparente">
    <div class="flex w-full justify-center flex-wrap md:bg-agave bg-no-repeat 2xl:bg-custom 2xl:bg-left-custom lg:bg-left-custom-lg lg:bg-custom-lg md:bg-left-custom-md md:bg-custom-md md:mt-24 xs:mt-[10%]">
    
    <div className="xs:w-full xs:block md:hidden md:w-1/3 flex justify-center">
          <div class="bg-agave bg-no-repeat bg-contain h-[208px] w-full bg-center-custom"></div>
        </div>
      <div className="w-1/3 flex justify-center">
          
        </div>
        <div className="xs:w-full md:w-1/3 flex justify-center flex-col">
        <div
            class="xs:block md:hidden xs:text-lg 2xl:text-6xl text-center lg:text-lg mainsail-calm mt-[5%]"
            data-aos="fade-up"
            style={{ color: "#80D8D0"}}
            data-aos="fade-in">
            <span > Our Tequila </span>
          </div>
          <div class="tracking-1widest 2xl:text-base xs:text-center md:text-right md:text-4xs mt-[15%] xs:text-custom1xs xs:px-[5%] texto-parrafos" data-aos="fade-in">
          Puerto Bello is a premium tequila, 100% agave made in Mexico. It is considered a Premium
            Tequila because it is made in small batches production processes, cooked in autoclaves
            (high pressure) and the most important innovation to the process happens in fermentation,
            where we play classical music while the mixture rests. This is known as the Mozart method
            and is one of the most favorable techniques for the good taste of our tequilas.
          </div>
        </div>
        
        <div className="xs:w-full md:w-1/3 flex justify-center flex-col">
          <div
            class="xs:hidden md:block xs:text-lg 2xl:text-6xl text-center lg:text-lg mainsail-calm mt-[5%]"
            data-aos="fade-up"
            style={{ color: "#80D8D0"}}
            data-aos="fade-in">
            <span > Our Tequila </span>
          </div>
        </div>
        <div className="xs:w-full md:w-1/3 flex justify-center">
        
        </div>
        <div className="xs:w-full md:w-1/3 flex justify-center flex-col">
          <div
            class="xs:block md:hidden xs:text-lg 2xl:text-6xl text-center lg:text-lg mainsail-calm mt-[10%] "
            data-aos="fade-up"
            style={{ color: "#80D8D0"}}
            data-aos="fade-in">
            <span > Our Mission </span>
          </div>
          <div class="tracking-1widest 2xl:text-base xs:text-center md:text-right md:text-4xs xs:mt-[10%] mb:mt-[30%] xs:text-custom1xs xs:px-[5%] xs:mb-[20%] md:mb-[40%] texto-parrafos" data-aos="fade-in">
          Our goal is to transform the alcoholic beverages industry and create high value
            experiences for consumers.
          </div>
        </div>
        
        <div className="xs:w-full md:w-1/3 flex justify-center flex-col">
          <div
            class="xs:hidden md:block xs:text-lg 2xl:text-6xl text-center lg:text-lg mainsail-calm mt-[28%] lg:mb-[40%]"
            data-aos="fade-up"
            style={{ color: "#80D8D0"}}
            data-aos="fade-in">
            <span > Our Mission </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Home = () =>  {

  const [menuOpen, setMenuOpen] = useState(false);
  function handleMenuToggle() {
		setMenuOpen(menuOpen ? false : true)
	}

  useEffect(() => {
    document.title = "Tequila Puerto Bello - Home";
    AOS.init({
      // duration : 5000
    });
  }, []);
  return (
    <div style={{ backgroundColor: "#4CC1BB", color: " white" }}>
      <Nav onMenu={ () => handleMenuToggle() } color="text-white"/>
      <MasterTheMomentSection />
      <AwardsSection />
      <SmallCactusSection />
      <OurJourneySection />
      <Footer />
      <Menu visible={ menuOpen } onClose={ () => handleMenuToggle() } />
    </div>
  )
}

export default Home;
