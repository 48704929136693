import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Process,
  OurTequila,
  ContactUs,
  Cocktail,
  Home,
  PrivacyPolice,
  TermsAndConditions,
  MeetTheTequila,
  EntryQuestion,
} from "@pages";

const App = () => {
  const hasAnsweredQuestion = localStorage.getItem('puerto-bello-tequila-entry-question');
  return hasAnsweredQuestion ?
    (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/cocktail" component={Cocktail} />
          <Route exact path="/our-tequila" component={OurTequila} />
          <Route exact path="/our-tequila/:meet" component={MeetTheTequila} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/process" component={Process} />
          <Route exact path="/privacy-policy" component={PrivacyPolice} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
        </Switch>
      </Router>
    )
  :
    (
      <EntryQuestion />
    );
}

export default App;
