import React, {useEffect, useState} from "react";
import { Mask, Nav, Footer,Menu } from "@components";
import AOS from "aos";
import "aos/dist/aos.css";
import "@/fixcss.css";

const MeetTheTequila = ({ match }) => {
  useEffect(() => {
    document.title = "Tequila Puerto Bello - " + data[match.params.meet].Head.tipo;
  }, []);
  return (
    <>
      <Head2 tequila={ match.params.meet } />
      <FirstSection tequila={ match.params.meet } />
      <SecondSection tequila={ match.params.meet } />
      <SecondSection2 tequila={ match.params.meet }  />
      <ThirdSection tequila={ match.params.meet } />
      <ThirdSectionNew tequila={ match.params.meet } />
      <Footer />
    </>
  );
};

const data = {
  "puerto-bello-blanco": {
    Head: {
      tipo: "Blanco",
      principalText: <div><p>
      Bright up to crystal. Served directly from distillation in stills, with a medium
      body.
    </p><br />
    <p>A taste of nut and peach make it to a clean and pleasant end to the mouth.</p><br/>
    <p> Perfect to sip a shot or to prepare a margarita.</p></div>,
      backgroundColor: '#4cc1bb',
      src:"/images/Grupo 786@2x.png",
      buyMeLink: 'https://jensensliquors.com/puerto-bello-blanco-tequila.html',
      burgerColor:"#4CC1BB"
    },
    First: {
      backgroundColor: '#4cc1bb',
      aging:'No aging',
      image:'/images/first_blanco.jpg'
    },
    Second: {
      backgroundColor: '#80d8d0',
      color: '#4cc1bb',
      colorText:'Crystal clear',
      noseText:'Notes of freshly baked agave and citrus',
      palateText:'light on the palate, easy to drink, with dry soft texture, great for mixing cocktails',
      colorImg:'/images/Shot_1.png',
      noseImg:'/images/Grupo 815.png',
      noseImgWidth: "21vw",
      palateImg:'/images/Margarita_1.png',
      palateImgWidth: "13.91vw",
      
    },
    Third: {
      tipo: "Blanco",
      backgroundColor:'#4cc1bb',
      images: [
        '/images/Blanco/Blanco01.jpg',
        '/images/Blanco/Blanco02.jpg',
        '/images/Blanco/Blanco03.jpg',
        '/images/Blanco/Blanco04.jpg',
        '/images/Blanco/Blanco09.jpg',
        '/images/Blanco/Blanco06.jpg',
        '/images/Blanco/Blanco07.jpg',
        // '/images/Blanco/80945.jpg',
        // '/images/Blanco/Copy of IMG_2672.jpg',
        // '/images/Blanco/DSC_0635-2.jpg',
        // '/images/Blanco/IMG_2289.jpg',
        // '/images/Blanco/IMG_2830.jpg',
        // '/images/Blanco/IMG_3380.jpg',
        // '/images/Blanco/IMG_3388.jpg',
        // '/images/Blanco/IMG_3427.jpg',
        // '/images/Blanco/IMG_3463.jpg',
        // '/images/Blanco/IMG_43192.jpg',
        // '/images/Blanco/IMG_5072.jpg',
        // '/images/Blanco/IMG_5186.jpg',
        // '/images/Blanco/IMG_6783.jpg',
        // '/images/Blanco/IMG_6792.jpg',
        // '/images/Blanco/IMG_6793.jpg',
        // '/images/Blanco/IMG_7957.jpg',
        // '/images/Blanco/IMG_9541.jpg',
        // '/images/Blanco/IMG_9883.JPG',
        // '/images/Blanco/Copy of blanco.png',
      ],
    }
  },
  "puerto-bello-reposado": {
    Head: {
      tipo: "Reposado",
      principalText: <div><p>
      This Tequila is aged in white american oak barrels.
    </p><br />
    <p>It releases an apple bouquet to the nose with some citrus and nut notes.</p><br/>
    <p>At the mouth, its soft taste of nut and wood will be perceived. At the very end, it develops a pure sense of cooked agave.</p></div>,
      backgroundColor: '#e8aa4c',
      src:"/images/Grupo 796@2x.png",
      buyMeLink: 'https://www.checkersliquors.com/product/puerto-bello-tequila-reposado-750ml/',
      burgerColor:"#E8AA4C"
    },
    First: {
      backgroundColor: '#e8aa4c',
      aging:'8 months',
      image:'/images/second_reposado.jpg'
    },
    Second: {
      backgroundColor: '#ebbd78',
      color: '#c25b55',
      colorText:'Light straw color',
      noseText:'Subtle oak notes with light toasted nuts and spices',
      palateText:'Elegant and delicate, with butterscotch notes, nice for sipping',
      colorImg:'/images/Reposado.png',
      noseImg:'/images/Group 809.png',
      noseImgWidth: "13.6vw",
      palateImg:"/images/Caramelo.png",
      palateImgWidth: "10vw",
    },
    Third: {
      tipo: "Reposado",
      backgroundColor:'#e8aa4c',
      images: [
        '/images/Reposado/Reposado01.jpg',
        '/images/Reposado/Reposado02.jpg',
        '/images/Reposado/Reposado03.jpg',
        '/images/Reposado/Reposado04.jpg',
        '/images/Reposado/Reposado05.jpg',
        '/images/Reposado/Reposado06.jpg',
        '/images/Reposado/Reposado07.jpg',

        // '/images/Reposado/Copy of IMG_2526.jpg',
        // '/images/Reposado/Copy of IMG_2587.jpg',
        // '/images/Reposado/DSC_0637-2.jpg',
        // '/images/Reposado/DSC_0666.jpg',
        // '/images/Reposado/IMG_20190708.jpg',
        // '/images/Reposado/IMG-202101jpg.jpg',
        // '/images/Reposado/IMG-202104jpg.jpg',
        // '/images/Reposado/IMG_2597.jpg',
        // '/images/Reposado/IMG_2612.jpg',
        // '/images/Reposado/IMG_30712.jpg',
        // '/images/Reposado/IMG_3363.jpg',
        // '/images/Reposado/IMG_3378.jpg',
        // '/images/Reposado/IMG_3408.jpg',
        // '/images/Reposado/IMG_3429.jpg',
        // '/images/Reposado/IMG_5145.jpg',
        // '/images/Reposado/IMG_5195.jpg',
        // '/images/Reposado/IMG_6789.jpg',
        // '/images/Reposado/IMG_7949.jpg',
        // '/images/Reposado/IMG_8017.jpg',
        // '/images/Reposado/IMG_9066.jpg',
        // '/images/Reposado/IMG_9139.jpg',
        // '/images/Reposado/JJ4.jpg',
      ],
    }
  },
  "puerto-bello-añejo": {
    Head: {
      tipo: "Añejo",
      principalText: <div><p>
      Pure delicacy. Añejo develops sweet aromas of apple and fruits.
    </p><br />
    <p>This Añejo is ideal to drink it on the rocks because of its sense of apple and nut in the nose, and wood on the palate.</p><br/>
    </div>,
      backgroundColor: '#4ab78e',
      src:"/images/Grupo 798@2x.png",
      buyMeLink: 'https://www.checkersliquors.com/product/puerto-bello-tequila-anejo-750ml/',
      burgerColor: "#2D9870"
    },
    First: {
      backgroundColor: '#2D9870',
      aging:'1.5 years',
      image:'/images/third_anejo.jpg'
    },
    Second: {
      backgroundColor: '#4AB78E',
      color: '#80d8d0',
      colorText:'Dark gold',
      noseText:'Rich but mellow and pleasant, with vanilla notes',
      palateText:'Full creamy texture, with complex flavors of pepper dried fruit and oak, great with a light cigar',
      colorImg:'/images/Añejo.png',
      noseImg:'/images/Grupo 789.png',
      noseImgWidth: "12.9vw",
      palateImg:'/images/Margarita_1.png',
      palateImgWidth: "10vw",
    },
    Third: {
      tipo: "Añejo",
      backgroundColor:'#2d9870',
      images: [
        '/images/Anejo/Anejo01.jpg',
        '/images/Anejo/Anejo02.jpg',
        '/images/Anejo/Anejo03.jpg',
        '/images/Anejo/Anejo04.jpg',
        '/images/Anejo/Anejo05.jpg',
        '/images/Anejo/Anejo06.jpg',
        '/images/Anejo/Anejo07.jpg',
        // '/images/Añejo/Copy of IMG_2782-2.jpg',
        // '/images/Añejo/Copy of IMG_5241.jpg',
        // '/images/Añejo/DSC_0636.jpg',
        // '/images/Añejo/IMG_104645.jpg',
        // '/images/Añejo/IMG_2552.jpg',
        // '/images/Añejo/IMG_2748.jpg',
        // '/images/Añejo/IMG_3366.jpg',
        // '/images/Añejo/IMG_3400.jpg',
        // '/images/Añejo/IMG_3439.jpg',
        // '/images/Añejo/IMG_3467.jpg',
        // '/images/Añejo/IMG_41782.jpg',
        // '/images/Añejo/IMG_4594.jpg',
        // '/images/Añejo/IMG_46312.jpg',
        // '/images/Añejo/IMG_5099.jpg',
        // '/images/Añejo/IMG_6787.jpg',
        // '/images/Añejo/IMG_6791.jpg',
        // '/images/Añejo/IMG_8032.jpg',
        // '/images/Añejo/JJ7.jpg'
      ],
    }
  }
}

const Head = ({ tequila }) => {
  const sectionData = data[tequila].Head;
  return (
    <div className="relative w-full h-screen bg-[#F8F6EF]">
      <Mask color="soft-blue" />
      <Nav color={sectionData.backgroundColor} />
      <div className="flex h-5/6 w-3/4 mx-auto py-14 items-center justify-center max-w-screen-2xl">
        <div className="flex flex-1 items-center">
        <div className="flex flex-1 flex-col w-1/2 flex-1">
            <div className="mt-12 space-y-8">
              <h1 className="text-2xl font-normal font-mainsailslab text-[#231F20] uppercase meetOurTequilaTitulo" data-aos="fade-in"  data-aos-duration="2000">
                Puerto Bello <br /> { sectionData.tipo }
              </h1>
              <div className="space-y-6 text-base text-[#231F20] font-quicksand font-medium tracking-widest meetOurTequilaDescripcion" data-aos="fade-in"  data-aos-duration="3000">
                {sectionData.principalText}
              </div>
            </div>
            <a href={ sectionData.buyMeLink } className="px-12 py-4 mt-24 mr-auto text-lg tracking-widest text-white uppercase font-bold font-quicksand buttonBuyMeMeetTequila" style={{ backgroundColor: sectionData.backgroundColor }}>
              Buy Me
            </a>
          </div> 
            <img
              src={sectionData.src}
              alt="Tequila Puerto Bello"
              className="w-2/3" style={{
                marginLeft: "calc(3vw - 200px)",
                marginRight: "calc(3vw - 200px)"
              }}
            />
        </div>
      </div>
    </div>
  );
};

const Head2 = ({ tequila }) => {
  const sectionData = data[tequila].Head;
  const [menuOpen, setMenuOpen] = useState(false);
  function handleMenuToggle() {
		setMenuOpen(menuOpen ? false : true)
	}
  return (
    <div className="relative w-full h-auto bg-[#F8F6EF]">
      <Mask color="soft-blue" />
      <Nav onMenu={ () => handleMenuToggle() } color={sectionData.backgroundColor} burgerColor={sectionData.burgerColor}/>

      <div className="seccionHeaderMeetTequila">
        <div style={{width:'40%'}}>
            <h1 className="text-2xl font-normal font-mainsailslab text-[#231F20] uppercase meetOurTequilaTitulo " data-aos="fade-in"  data-aos-duration="2000">
                    Puerto Bello <br /> { sectionData.tipo }
            </h1>
            <div className="space-y-6 text-base text-[#231F20] font-quicksand font-medium tracking-widest meetOurTequilaDescripcion" data-aos="fade-in"  data-aos-duration="3000">
              {sectionData.principalText}
            </div>
            <a href={ sectionData.buyMeLink } className="px-12 py-4 mt-24 mr-auto text-lg tracking-widest text-white uppercase font-bold font-quicksand buttonBuyMeMeetTequila" style={{ backgroundColor: sectionData.backgroundColor }}>
              Buy Me
            </a>
        </div>
        <div style={{width:'60%', display:"flex", justifyContent:"center", alignItems:"flex-end"}}>
          <img
              src={sectionData.src}
              alt="Tequila Puerto Bello"
              style={{width:'100%'}}
            />
        </div>
      </div>
      <Menu visible={ menuOpen } onClose={ () => handleMenuToggle() } />
    </div>
  );
};

const FirstSection = ({ tequila }) => {
  const sectionData = data[tequila].First;
  return (
    <div className="w-full h-screen height80 padding50" style={{
      backgroundColor: sectionData.backgroundColor
    }}>
      <div className="grid items-center h-full grid-cols-6 gap-6 sectionHowMade">
        <div className="col-start-2 col-end-4 px-6 text-white space-y-14">
            <div className="space-y-4">
              <h2 className="text-2xl font-turquoise">How it’s made</h2>
              <p className="text-base font-medium font-quicksand">
                We do the most traditional spirit of the world. With some certain improvements, of course.
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-turquoise">Aging</h3>
                <p className="text-lg font-medium font-quicksand">
                  { sectionData.aging }
                </p>
            </div>
            <div className="space-y-2">
                <h3 className="text-xl font-turquoise">Alcohol</h3>
          <p className="text-lg font-medium font-quicksand">40% vol</p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-turquoise">Bottle size</h3>
              <p className="text-lg font-medium font-quicksand">750 ml</p>
            </div>
        </div>
        <div className="col-start-4 col-end-9 imagenHowMade">
          <img src={sectionData.image} alt="" />
        </div>
      </div>
    </div>
  )
};

const SecondSection = ({ tequila }) => {
  const sectionData = data[tequila].Second;
  return (
    <div className="flex-col w-full h-screen flex items-center justify-center meetOurTequilaPackageSection seccionPackageDesktop" style={{backgroundColor: sectionData.backgroundColor, display:'flex !important'}}>
      <div className="flex justify-start items-start w-2/3 sectionPackageMeetOurTequila">
        <div className="px-12 mb-10 tituloPackage">
          <h3 className="text-5xl font-turquoise" style={{ 
            color: sectionData.color
           }}>Package</h3>
        </div>
        <div className="flex-1 flex justify-start mb-10 w-1/2 parrafoPackage">
          <p className="mt-0 text-base font-medium text-center font-quicksand text-white parrafoPackage">
            Simple and elegant, with a clean look through <br />
            the glass.
          </p>
        </div>
      </div>
      <div className="grid justify-center grid-cols-3 text-white p-36" style={{ gridTemplateColumns: "repeat(3,minmax(0,1fr)", padding: "0", width:"70%"}}>
        <div className="flex flex-col col-start-1 col-end-2 mt-16 mb-10 mx-auto">
          <h4 className="mb-2 text-lg text-center font-turquoise tituloPackage">Color</h4>
          <p className="text-base text-center font-quicksand font-medium parrafoPackage">
            { sectionData.colorText }
          </p>
        </div>
        <div className="flex flex-col col-start-2 col-end-3 mt-16 mb-10 mx-auto" style={{width:"15.2vw"}}>
            <div className="">
              <h4 className="mb-2 text-lg text-center font-turquoise tituloPackage">Nose</h4>
                <p className="text-base text-center font-quicksand font-medium parrafoPackage">
                  { sectionData.noseText }
                </p>
            </div>
          </div>
          <div className="flex flex-col col-start-3 col-end-4 mt-16 mb-10 mx-auto" style={{width:"20.8vw"}}>
            <div>
              <h4 className="mb-2 text-lg text-center font-turquoise tituloPackage">Palate</h4>
                <p className="text-base text-center font-quicksand font-medium parrafoPackage">
                  { sectionData.palateText }
                </p>
            </div>
          </div>
          <div className="flex col-start-1 col-end-4 items-center">
            <div className="flex-1">
              <img src={ sectionData.colorImg } style={{width:"6.1vw"}} alt="shot tequila" className="mx-auto mt-10 h-auto" />
            </div>
            <div className="flex-1">
              <img src={ sectionData.noseImg } style={{width: sectionData.noseImgWidth }} alt="shot tequila" className="mx-auto mt-10 h-auto" />
            </div>
            <div className="flex-1">
              <img src={ sectionData.palateImg } style={{width: sectionData.palateImgWidth}} alt="shot tequila" className="mx-auto mt-10 h-auto" />
            </div>
          </div>
      </div>
    </div>
  )
};

const SecondSection2 = ({ tequila }) => {
  const sectionData = data[tequila].Second;
  return (
    <div className="flex-col w-full h-auto flex items-center justify-center meetOurTequilaPackageSection seccionPackageMovil" style={{backgroundColor: sectionData.backgroundColor}}>
      <div className="flex justify-start items-start w-2/3 sectionPackageMeetOurTequila">
        <div className="px-12 mb-10 tituloPackage">
          <h3 className="text-5xl font-turquoise" style={{ 
            color: sectionData.color
           }}>Package</h3>
        </div>
        <div className="">
          <p className="mt-0 text-base font-medium text-center font-quicksand text-white parrafoPackage">
            Simple and elegant, with a clean look through <br />
            the glass.
          </p>
        </div>
      </div>
      <div className="grid justify-center grid-cols-3 text-white p-36" style={{ padding: "0", width:"100%", display:'flex', flexDirection:'column'}}>
        <div className="flex flex-col col-start-1 col-end-2 mt-16 mb-10 mx-auto">
          <h4 className="mb-2 text-lg text-center font-turquoise tituloPackage">Color</h4>
          <p className="text-base text-center font-quicksand font-medium parrafoPackage">
            { sectionData.colorText }
          </p>
          <img src={ sectionData.colorImg } style={{width:"15vw"}} alt="shot tequila" className="mx-auto mt-10 h-auto" />
        </div>
        <div className="flex flex-col col-start-2 col-end-3 mt-16 mb-10 mx-auto">
            <div className="">
              <h4 className="mb-2 text-lg text-center font-turquoise tituloPackage">Nose</h4>
                <p className="text-base text-center font-quicksand font-medium parrafoPackage">
                  { sectionData.noseText }
                </p>
                <img src={ sectionData.noseImg } style={{width:'35vw' }} alt="shot tequila" className="mx-auto mt-10 h-auto" />
            </div>
          </div>
          <div className="flex flex-col col-start-3 col-end-4 mt-16 mb-10 mx-auto" >
            <div>
              <h4 className="mb-2 text-lg text-center font-turquoise tituloPackage">Palate</h4>
                <p className="text-base text-center font-quicksand font-medium parrafoPackage">
                  { sectionData.palateText }
                </p>
                <img src={ sectionData.palateImg } style={{width: '35vw'}} alt="shot tequila" className="mx-auto mt-10 h-auto" />
            </div>
          </div>
      </div>
    </div>
  )
};

const ThirdSection = ({ tequila }) => {
  const sectionData = data[tequila].Third;
  const [initialIndex, setInitialIndex] = React.useState(0);

  const nextCondition = initialIndex + 3  === sectionData.images.length 
  const nextVisibility = nextCondition ? 'hidden' : 'visible';

  const mobileNextCondition = initialIndex + 1  === sectionData.images.length 
  const mobileNextVisibility = mobileNextCondition ? 'hidden' : 'visible';

  const prevCondition  = initialIndex === 0;
  const prevVisibility = prevCondition ? 'hidden' : 'visible';
  // preload images for cache
  const images = React.useMemo(() => 
    sectionData.images.map(image => {
      const img = new Image();
      img.src = image;
      return img; 
    })
  ,[sectionData]);
  const mobileImages = React.useMemo(() => [null,...sectionData.images, null],[]); 

  useEffect(() => {
    AOS.init({
      // duration : 5000
    });
  }, []);

  return (
    <div className="w-full h-screen seccionPackageSharing height70" style={{
      backgroundColor: sectionData.backgroundColor,
      overflowX:'hidden'
    }}>
      <div className="flex flex-col items-center justify-center h-full gap-12 ">
        <h3 className="text-3xl text-white font-turquoise tituloSharing">Sharing { sectionData.tipo }</h3>
        <div className="flex flex-col gap-8 w-5/6">
          <div className="flex justify-between w-full text-white ml-4">
            <button className='text-base font-medium font-quicksand' 
              style={{ visibility : prevVisibility}}
              onClick={()=>setInitialIndex(initialIndex-1)}
            >
              PREVIOUS
            </button>
            <button className='text-base font-medium font-quicksand mr-4 lg:hidden' 
              onClick={()=>setInitialIndex(initialIndex+1)}
              style={{ visibility : mobileNextVisibility}}
            >
              NEXT
            </button>
            <button className='text-base font-medium font-quicksand mr-4 hidden lg:inline-block' 
              onClick={()=>setInitialIndex(initialIndex+1)}
              style={{ visibility : nextVisibility}}
            >
              NEXT
            </button>
          </div>
          <div className="slideshow grid-cols-3 gap-24 hidden lg:grid">
            { sectionData.images.slice(initialIndex, initialIndex + 3).map(
              image =>( <img key={image} src={image} style={{height:"26vw"}} alt="" />)
            )}
          </div>
    <div className="mobile-slideshow grid grid-cols-3 gap-24 lg:hidden" style={{width:"175%", marginLeft:"-32.5%"}}>
            { mobileImages.slice(initialIndex, initialIndex + 3).map(
              image =>( <img key={image} src={image} style={{width:"60vw"}} alt="" />)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ThirdSectionNew = ({ tequila }) => {
  const sectionData = data[tequila].Third;
  const [initialIndex, setInitialIndex] = React.useState(0);

  const nextCondition = initialIndex + 3  === sectionData.images.length 
  const nextVisibility = nextCondition ? 'hidden' : 'visible';

  const mobileNextCondition = initialIndex + 1  === sectionData.images.length 
  const mobileNextVisibility = mobileNextCondition ? 'hidden' : 'visible';

  const prevCondition  = initialIndex === 0;
  const prevVisibility = prevCondition ? 'hidden' : 'visible';
  // preload images for cache
  const images = React.useMemo(() => 
    sectionData.images.map(image => {
      const img = new Image();
      img.src = image;
      return img; 
    })
  ,[sectionData]);
  const mobileImages = React.useMemo(() => [null,...sectionData.images, null],[]); 

  useEffect(() => {
    AOS.init({
      // duration : 5000
    });
  }, []);

  return (
    <div className="w-full h-screen seccionPackageMovil height90 padding0" style={{
      backgroundColor: sectionData.backgroundColor,
      overflowX:'hidden'
    }}>
      <div className="flex flex-col items-center justify-center gap-12 ">
        <h3 className="text-3xl text-white font-turquoise tituloSharing">Sharing { sectionData.tipo }</h3>
        <div className="flex flex-col gap-8 w-5/6">
          
          <div className="contenedorBotonesGaleria">
          <button className='text-base font-medium font-quicksand' 
              style={{ visibility : prevVisibility}}
              onClick={()=>setInitialIndex(initialIndex-1)}
            >
              PREVIOUS
            </button>
            <button className='text-base font-medium font-quicksand mr-4 lg:hidden' 
              onClick={()=>setInitialIndex(initialIndex+1)}
              style={{ visibility : mobileNextVisibility}}
            >
              NEXT
            </button>
            <button className='text-base font-medium font-quicksand mr-4 hidden lg:inline-block' 
              onClick={()=>setInitialIndex(initialIndex+1)}
              style={{ visibility : nextVisibility}}
            >
              NEXT
            </button>
          </div>
          <div className="imagenGaleria">
              {sectionData.images.slice(initialIndex, initialIndex + 3).map(
                image =>( <img key={image} src={image} alt="" />)
              )}
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default MeetTheTequila;
