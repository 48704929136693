import React, {useState} from "react";

// este component será reutilizable, y será el mismo para cada uno de los enlaces
// solo debería cambiar la posicion, el link selecionado y el contenido. pero el layout
// debería ser el mismo.

// PD: no está terminado. puede verse extraño

const CocktailSectionNew = ({ className, maxWidth, mix, textColor = "text-black", mask = "blue", handleClick, sliderColor }) => {
  const tabs = mix.link.all;
  // console.log(mix.link.all);

  const [state, setState] = useState({
    value: 0 
  });

  const [sliderState, setSliderState] = useState(50);

  const changeState = (value) => {
    setState({ ...state, value: value });
  };

  const cambioTrago = (posicion, link, index ) =>{
    setSliderState(posicion);
    setTimeout(function(){
      handleClick(link);
      changeState(index);
    },600);
    
  }
  return (
    <div className={["flex relative w-full xs:bg-none bg-contain bg-right-bottom bg-no-repeat", className, mask === 'blue' ? 'bg-mask-blue' : mask === 'bisque' ? 'bg-mask-bisque' : null].join(" ")}>
      <div className={["mx-auto mt-8", maxWidth].join(" ")}>

        <div className="grid xs:grid-cols-1 sm:grid-cols-3 pt-2" style={{transition:'all 0.5s'}}>

          <div className="flex flex-col xs:order-1 sm:order-1 gap-6 tracking-widest sm:pr-8 2lg:pr-12 2xl:pr-12">
            <div className="">
              <h2 className={["xs:mb-0 sm:mb-8 text-base lg:text-custom6sm sm:text-2sm md:text-2sm xl:text-1xl 2xl:text-1xl font-turquoise", textColor].join(" ")}>
                {mix.description.title}
              </h2>
              <p className="font-quicksand sm:text-custom2xs md:text-custom2xs lg:text-sm xl:text-base 2xl:text-base">{mix.description.content}</p>
            </div>
            <div>
              <h2 className={["mt-10 mb-8 text-base xs:mt-0 sm:mt-8 sm:text-2sm md:text-2sm lg:text-custom6sm xl:text-lg 2xl:mt-8 2xl:text-lg font-turquoise", textColor].join(" ")}>Ingredients</h2>
              <ul className="font-quicksand sm:text-custom3xs md:text-custom3xs lg:text-sm 2xl:text-base">
                {mix.ingredients.map((ingredient, index) => (
                  <li key={index}>{ingredient}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="flex flex-col xs:order-3 sm:order-2 gap-0 tracking-widest sm:pr-0 lg:pr-0 2xl:pr-12">
            {mix.recipe.map((step, index) => (
              <div className="2xl:mb-8" key={`recip-` + index}>
                <div className={["font-quicksand sm:text-custom5xs md:text-custom5xs lg:text-custom4xs xl:text-base 2xl:text-base mt-0 mb-4 2xl:mt-0 2xl:mb-8 uppercase", index === 0 ? 'mt-10 mb-8 2xl:mt-8' : null ].join(' ')} style={{fontWeight:300}}>{step.title}</div>
                <p className="font-quicksand sm:text-custom2xs md:text-custom2xs lg:text-sm xl:text-base 2xl:text-base mb-4">{step.content}</p>
              </div>
            ))}
          </div>

          <div className="flex flex-col xs:my-7 xs:items-center xs:order-2 sm:my-0 sm:items-end sm:order-3">
            <img
              src= {`${mix.image.src}`}
              alt= {`${mix.image.alt}`}
              className="z-10 xs:w-full sm:w-5/6 object-contain sm:ml-auto lg:ml-auto xl:ml-auto 2xl:ml-auto mr-0"
            />
          </div>
        </div>
        {/* placeholder. lautaro hará el componente */}
        <div className="xs:hidden sm:block">
          <ul className="flex self-center my-20 sm:text-custom6xs md:text-custom6xs lg:text-custom7sm xl:text-lg 2xl:text-lg justify-evenly mr-32 li_elementCocktail" style={{fontFamily:'Turquoise', marginBottom:'1rem'}}>
            {mix.link.all.map((link, index) => (
              <li className={["cursor-pointer"].join(" ")} key={`${mix.description.title}-link-${index}`} onClick={(e) => {  cambioTrago((e.nativeEvent.layerX - e.nativeEvent.offsetX +(e.nativeEvent.target.clientWidth/2) -24), link, index) } } style={{transition:'all 0.3s',fontSize:20,color:(index===state.value? ( mask ==='blue'? '#4cc1bb':'#c25b55'):'#ffffff')}} >
                {link}
              </li>
            ))}
          </ul>
          
        </div>
        <div id="sliderTragos" className="sliderTragos" style={{marginLeft:sliderState, transition:'all 0.5s', marginBottom:30}}>
            <img src="/images/Shot_1.png" alt="Shot" className="sm:w-[21px] md:w-[21px] lg:w-[28px] xl:w-[40px] 2xl:w-[52px] animationCoktail" />
          </div>
          <hr style={{height:1, border:'1px solid #fff', width:'87%', marginTop:-60, marginBottom:100 }}></hr>
      </div>
      
    </div>
  );
};

export default CocktailSectionNew;
