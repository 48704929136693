const EntryQuestion = () => {
  return (
    <div style={{ backgroundColor: "#4CC1BB", color: " white" }} className="h-screen w-screen flex items-center">
    <div className='h-screen w-screen relative flex flex-col justify-center privacy-police bg-privacy-police align-center p-0' 
      style={{ 
        padding: 0,
        height: "100%",
        backgroundSize: "120vw auto",
        backgroundPosition: "center center"
      }}
    >
    <div className="mx-auto mainsail-calm text-2xl sm:text-hero text-center w-1/2 mt-20 p-0">
          <div class="mainsail-calm">
            Are you 21 years or older? 
          </div>
          <div class="w-5/6 mx-auto flex justify-around mt-16">
            <button
              class="font-quicksand font-bold bg-[#E8AA4C] text-base"
              style={{ width: "10.4vw", minWidth:"100px", height:"7.3vh"}}
    onClick={() => { localStorage.setItem('puerto-bello-tequila-entry-question','yes'); window.location.reload()} }
            >
              YES 
            </button>
            <button
              class="font-quicksand font-bold bg-[#EBBD78] text-base"
              style={{ width: "10.4vw", minWidth:"100px", height:"7.3vh"}}
              onClick={() => { alert("We’re sorry! \nThis website is for adults only."); }}
            >
              NO 
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};
export default EntryQuestion;
