import React, {Component} from "react";
import axios from 'axios';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    }
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  resetForm(){
    this.setState({name: "", email: "", message: ""})
  }

  handleSubmit(event) {
    event.preventDefault();
    // console.log(this.state);
	let baseURL="https://pbtequila.marabunta.work";

    axios({
      method: "POST",
      url: baseURL+"/api/subscribe_newsletter.php",
      data:  this.state,
    }).then((response)=>{
      console.log(response.data);
      if (response.data.status === 'success') {
        alert("Thank you! \nWe’ve received your information.");
        this.resetForm()
      } else if(response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }

  render() {
    return (
      <div className="footer text-white py-10 xl:pt-[102px] xl:pb-[64px] pb-[84px] sm:px-20 md:px-[103px] xl:px-0">
	<div className='w-full h-auto grid grid-cols-1 lg:grid-cols-footer xl:grid-cols-1 auto-rows-auto sm:mt-10 lg:mt-[70px] xl:mt-0 lg:mt-[84px] relative float-left'>
		<div className='flex justify-center w-full lg:w-[165px] h-auto sm:h-[165px] xl:w-[310px] xl:h-[310px] xl:mx-auto xl:mb-[50px] sm:mb-10 mb-16'>
			<img className='w-full h-auto sm:px-0 px-[40px] sm:h-full object-contain relative float-left' alt='logo' src={ `${process.env.REACT_APP_LINK}/images/Grupo 696.png` } />
		</div>
		<div className="grid grid-cols-1 auto-rows-auto sm:grid-cols-2 sm:grid-rows-1 gap-0 sm:gap-[59.5px] w-full xl:w-4/5 mx-auto mainsail-calm">
			<div
				className="w-full flex items-center justify-center sm:justify-end xl:inline-block xl:items-start xl:justify-start tracking-[3.6px] sm:tracking-[6px] xl:tracking-[12px] text-right uppercase text-[18px] sm:text-[30px] leading-[50px] xl:text-[60px] xl:leading-[120px] tequilaInYourInbox"
			>
				Tequila in your inbox
			</div>
			<div className="w-full text-right xl:pr-40 sm:px-0 px-[27px] flex justify-center flex-col">
        <form method="POST" onSubmit={this.handleSubmit.bind(this)}>
          <div className="w-full mb-8 xl:pr-8 mt-2 text-[16px] leading-[19px] sm:leading-[1] sm:text-[18px] xl:text-xl flex font-turquoise">
            <span>Email:</span>
            <input type="email" required value={this.state.email} onChange={this.onEmailChange.bind(this)} onInvalid={(e) => {e.target.setCustomValidity("Please, enter the information required.")} } onInput={ (e) => {e.target.setCustomValidity('')}} className="w-full bg-transparent flex-1 ml-5" style={{ borderBottom: "3px solid white" }} />
          </div>
          <button type="submit" className="button bg-[#4CC1BB] xl:tracking-[6px] tracking-[2.6px] py-[10px] sm:py-[12px] xl:py-4 px-8 uppercase w-full sm:w-auto relative float-right ml-auto text-center sm:leading-[16px] leading-[40px] text-[20px] sm:text-[13px] xl:text-lg quicksand font-bold xl:mr-8" style={{padding:'10px 30px', fontSize:20}}>
            Sign Up
          </button>
        </form>
			</div>
		</div>
	</div>
	<div className='w-full h-auto relative float-left flex xl:auto-rows-auto auto-rows-auto lg:flex-row-reverse xl:flex-col mt-10 lg:mt-[64px] xl:mt-0 sm:mb-16 xl:mb-0 lg:mb-0 flex-wrap'>
		<div className='w-full lg:w-2/6 xl:w-full h-auto flex lg:justify-end xl:justify-center justify-center xl:pt-[89px] xl:pb-[84px] xl:pr-0 pb-10 lg:pb-0 pr-0'>
			<div className="w-full sm:w-auto social-media sm:inline-block flex justify-between sm:px-0 px-[27px] items-center lg:justify-end">
				<div className="icon w-[60px] sm:w-[35px] xl:w-[60px] sm:bg-white p-0.5 rounded-xl relative float-left xl:mx-[49px]">
					<a target='_blank' href='https://www.instagram.com/officialpuertobello/?hl=es'>
						<img src="/images/Grupo 698.png" />
					</a>
				</div>
				<div className="icon w-[60px] sm:w-[35px] xl:w-[60px] sm:bg-white p-0.5 rounded-xl relative float-left sm:ml-[39px] xl:mx-[49px]">
					<a target='_blank' href='https://www.facebook.com/PuertoBelloTequila/'>
						<img src="/images/Grupo 697.png" />
					</a>
				</div>
				<div className="icon w-[60px] sm:w-[35px] xl:w-[60px] sm:bg-white p-0.5 rounded-xl relative float-left sm:ml-[39px] xl:mx-[49px]">
					<a target='_blank' href='https://www.youtube.com/channel/UCozL1xv-cxYe5Kd5ue9ONZg'>
						<img src="/images/Grupo 700.png" />
					</a>
				</div>
				<div className="icon w-[60px] sm:w-[35px] xl:w-[60px] sm:bg-white p-0.5 rounded-xl relative float-left sm:ml-[39px] xl:mx-[49px]">
					<a target='_blank' href='https://twitter.com/puerto_bello'>
						<img src="/images/Grupo 699.png" />
					</a>
				</div>
			</div>
		</div>
		<div className='w-full justify-center h-auto lg:w-4/6 xl:w-full flex lg:justify-start xl:justify-center flex-wrap' style={{fontFamily:'Quicksand', fontWeight:'400'}}>
			<div className="w-full sm:w-auto h-auto xl:h-auto links lg:mb-0 mb-7" >
				<a className="text-[16px] leading-[30px] font-medium sm:font-normal sm:w-auto w-full sm:text-left text-center sm:my-0 my-5 sm:text-[9px] sm:leading-[10px] xl:text-base mx-0 sm:mr-7 lg:mx-0 lg:mr-[44px] xl:mx-[28.5px] relative float-left textoFooter" href="/privacy-policy">Privacy Policy</a>
				{/* <div className="text-[16px] leading-[30px] font-medium sm:font-normal sm:w-auto w-full sm:text-left text-center sm:my-0 my-5 sm:text-[9px] sm:leading-[10px] xl:text-base mx-0 sm:mx-7 lg:mx-0 lg:mr-[44px] xl:mx-[28.5px] relative float-left">Shipping & Returns</div> */}
				<a className="text-[16px] leading-[30px] font-medium sm:font-normal sm:w-auto w-full sm:text-left text-center sm:my-0 my-5 sm:text-[9px] sm:leading-[10px] xl:text-base mx-0 sm:mx-7 lg:mx-0 lg:mr-[44px] xl:mx-[28.5px] relative float-left textoFooter" href="/terms-and-conditions">Terms & Conditions</a>
			</div>
			<div className="w-full sm:font-normal font-medium sm:text-white text-accent-blue h-auto lg:mt-[14px] mb-0 xl:mt-10 xl:mb-10 text-[14px] sm:px-0 px-[34px] sm:text-[6px] xl:text-base xl:text-center lg:text-left text-center notice leading-[30px] mt-0 textoFooter">
				Please drink Puerto Bello responsibly. Puerto Bello Tequila 40% Alc./Vol, imported by JP
				Promoter LLC , Miami, FL.
			</div>
		</div>
	</div>
  </div>
    )
  }
}

export default Footer;
