import React, {useState, useEffect} from "react";
import { Footer, Nav, Menu } from "@components";

const Hero = () => (
	<div className='text-white relative flex flex-col justify-center privacy-police bg-privacy-police align-center'>
    	<div className="mx-auto mt-6 mainsail-calm text-hero text-center">Privacy<br />Policy</div>
	</div>
)

const Content = () => (
	<div className='p-48 w-full h-auto bg-[#80D8D0]'>
		<p className='text-[18px] font-medium quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Effective 20 July 2021</p>
		<p className='text-[18px] font-medium quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Please read carefully before using this site</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>1. YOUR INFORMATION (SHARING)</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>We may share your personal data with third parties and affiliates who may use your information for the purposes described in this Privacy Policy. Our service providers process your personal data for data analytics and advertising of our products and services. We may disclose your personal data as permitted by law in order to investigate, prevent or take action regarding illegal activities, or other agreements, or as required by law. We do not sell your personal data to any third party. We use secure methods to transmit personal data.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>2. YOUR DATA</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>How do we collect and process the information from our digital visitors, our business partners and their employees, visitors to our premises.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>SOURCES</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>From you directly (for example, at the time of subscribing to any services offered), from your device or browser, and/or if you contact us, we may keep a record of that communication. From a company that employs you (Business Partner). From our affiliates, during networking events, and/or from publicly available sources. From our systems’ records.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>PERSONAL DATA THAT WE COLLECT AND PROCESS</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Name<br />Username<br />Address<br />Date of birth<br/>Email address<br />Operating system Browser type<br />Cookie data<br />Preferences regarding online marketing and tracking<br />IP address<br />Location Picture (photography) – During our events<br />Business address<br />Business email address<br />Business telephone number<br />Business fax number<br />Organisation<br />Job title or role<br />Business bank account details<br />Language of communication<br />Date of first contact<br />Information you submit to us when you contact us<br />Responses you provide as part of our surveys or other interactive services<br />Behavioural information from online, web, apps, email and social media, if we have your permission to do so.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>WHY DO WE COLLECT YOUR PERSONAL DATA?</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Our legitimate interest: Website and Management<br />Understand the market in which we operate<br />Learn about our digital users<br />Learn about performance of our digital services<br />Security, risk and crime prevention<br />Promote our goods and services<br />Learn about how our products or services may be used<br />Sharing information with advertising partners<br />Contractual Obligation<br />Your image (film or photo) at events to support our promotional campaign<br />*If you object to us using your personal data for the above purposes, please contact us using contact details set out in the section correspondent below.<br />*We do not sell your personal data to any third party.<br />*If you follow a link to and from the websites of our partner networks or affiliates, please note that these websites have their own privacy notices or policies. Please check their privacy notices or policies before you submit any personal data to these websites.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>3. YOUR RIGHTS</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>You can deny us the use of your personal data where we clarified that above.<br />You can ask for information from us on how we manage your personal data when prescribed by local regulations.<br />You can ask us to transfer a copy of your personal data that you provided to us.<br />If you gave us your consent to use your data, you can withdraw your consent at any time. Please note that we can still rely on the consent you gave us for processing your personal data before you withdraw your consent.<br />Please contact us using the contact details if you would like to exercise any of the above.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>4. UPDATES</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>We may revise this Privacy Policy at any time.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>5. UNDERAGE</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Our website is designed for adults only and we do not knowingly solicit any information from children or underage people regarding the legal drinking age.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>6. ABOUT US</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>PUERTO BELLO, ITS TRADE DRESS AND THE LOGO ARE TRADEMARKS. MADE IN MEXICO. IMPORTED BY JP PROMOTER LLC, MIAMI, FLORIDA, USA.</p>
		<p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>7. CONTACT DETAILS</p>
		<p className='text-[18px] quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>Please contact us with any comments, complaints or suggestions regarding this Privacy Policy, or our business in general via the contact points below.</p>
		{/* <p className='text-[18px] text-[#2D9870] font-bold quicksand tracking-[1.8px] mb-[60px] leading-[30px]'>PUERTO BELLO TEQUILA - USA</p> */}
		<p className='text-[18px] quicksand tracking-[1.8px] leading-[30px]'>PUERTO BELLO TEQUILA - USA admin@tequilapuertobello.com info@puertobellotequila.com JP PROMOTER LLC - USA admin@jppromoter.com info@jppromoter.com +1-786-484-7549 8424 NW 56TH STREET, MIAMI, FL, 33195 USA PROMOTORA JP SA DE CV - MÉXICO admin@jppromoter.com info@jppromoter.com Av. Cuauhtémoc 101-A, L6, Cantarranas, Cuernavaca, Morelos, 62448 MÉXICO GRUPO INDUSTRIAL TEQUILERO DE LOS ALTOS DE JALISCO - MÉXICO Carr Arandas-Tepatitlán Km 17.5, San Ignacio Cerro Gordo, Jalisco, 47190 MÉXICO +52-348-716-0310</p>
	</div>
)

const PrivacyPolice = () => {
const [menuOpen, setMenuOpen] = useState(false);
  function handleMenuToggle() {
		setMenuOpen(menuOpen ? false : true)
	}

	useEffect(() => {
		document.title = "Tequila Puerto Bello - Privacy Police";
	}, []);

  return (
	<div style={{ backgroundColor: "#4CC1BB", color: " white" }}>
		<Nav onMenu={ () => handleMenuToggle() } color="text-white"/>
		<Hero />
		<Content />
		<Footer />
		<Menu visible={ menuOpen } onClose={ () => handleMenuToggle() } />
	</div>
  );
};

export default PrivacyPolice;
