export const MixesWithBlanco = [
  {
    description: {
      title: "Mojito Tequila",
      content: "",
    },
    ingredients: [
      "5 Mint Leaves",
      "2 oz Puerto Bello Blanco",
      "1 oz Agave Syrup",
      "1/2 oz Lime Juice",
      "2 oz Sparkling Water"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "In a highball glass, muddle the mint, lime juice and agave syrup.",
      },
      {
        title: "Step 02",
        content: "Add the tequila and fill with ice.",
      },
      {
        title: "Step 03",
        content: "Top with sparkling water and stir gently to combine. ",
      },
      {
        title: "Step 04",
        content: "Garnish with a mint sprig and a lime wheel.",
      },
    ],
    image: {
      src: "/images/Mojito.jpg",
      alt: "A mojito Image",
    },
    link: {
      all: [
        "Mojito",
        "Paloma",
        "Tequila Sunrise",
        "Tequila Punch",
        "Margarita Blue",
        "Bloody Mary",
      ],
      current: "Mojito",
      image: "",
    },
  },
  {
    description: {
      title: "Paloma",
      content: "",
    },
    ingredients: [
      "2 oz Puerto Bello Blanco",
      "1/2 oz Lime Juice",
      "Grapefruit Soda",
      "Sparkling Water"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "Frost the glass with lime juice and salt.",
      },
      {
        title: "Step 02",
        content: "Fill it with ice and add Puerto Bello Blanco.",
      },
      {
        title: "Step 03",
        content: "Pour lime juice, grapefruit soda and sparkling water.",
      },
      {
        title: "Step 04",
        content: "Garnish with a grapefruit slice and enjoy!",
      },
    ],
    image: {
      src: "/images/Paloma.jpg",
      alt: "A paloma Image",
    },
    link: {
      all: [
        "Mojito",
        "Paloma",
        "Tequila Sunrise",
        "Tequila Punch",
        "Margarita Blue",
        "Bloody Mary",
      ],
      current: "Paloma",
      image: "",
    },
  },
  {
    description: {
      title: "Tequila Sunrise",
      content: "",
    },
    ingredients: [
      "2 oz Puerto Bello Blanco",
      "3/4 oz Grenadine Syrup",
      "3/4 oz Orange Juice",
      "1/2 oz Cherry Syrup"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "Pour ice into a glass, add the grenadine syrup and wait for it to go to the bottom. ",
      },
      {
        title: "Step 02",
        content: "Add Puerto Bello Blanco, orange juice and cherry syrup into a shaker with ice and shake until chilled.",
      },
      {
        title: "Step 03",
        content: "Serve over the crushed ice. Delicious!",
      }
    ],
    image: {
      src: "/images/sunrise_image.jpg",
      alt: "A Sunrise Image",
    },
    link: {
      all: [
        "Mojito",
        "Paloma",
        "Tequila Sunrise",
        "Tequila Punch",
        "Margarita Blue",
        "Bloody Mary",
      ],
      current: "Tequila Sunrise",
      image: "",
    },
  },
  {
    description: {
      title: "Tequila Punch",
      content: "",
    },
    ingredients: [
      "1/4 Cup Pineapple Juice",
      "1/4 Cup Orange Juice",
      "1/4 Cup Puerto Bello Blanco",
      "1/2 Teaspoon Lime Juice"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "Pour some crushed ice into a highball glass (for large drinks).",
      },
      {
        title: "Step 02",
        content: "Add pineapple juice, orange juice, Puerto Bello Blanco and lime juice.",
      },
      {
        title: "Step 03",
        content: "Stir well to combine and drink up!",
      }
    ],
    image: {
      src: "/images/TequilaPunch.jpg",
      alt: "Punch Image",
    },
    link: {
      all: [
        "Mojito",
        "Paloma",
        "Tequila Sunrise",
        "Tequila Punch",
        "Margarita Blue",
        "Bloody Mary",
      ],
      current: "Tequila Punch",
      image: "",
    },
  },
  {
    description: {
      title: "Margarita Blue",
      content: "",
    },
    ingredients: [
      "2 oz Puerto Bello Blanco",
      "1 oz Blue Curaçao",
      "1/2 oz Lime Juice",
      "Salt"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "Add Puerto Bello Blanco, Blue Curaçao and lime juice into a shaker with ice.",
      },
      {
        title: "Step 02",
        content: "Shake until well-chilled and strain into a glass over ice.",
      },
      {
        title: "Step 03",
        content: "Add a pinch of salt and enjoy your drink!",
      }
    ],
    image: {
      src: "/images/BlueM.jpg",
      alt: "Tequila Blue Image",
    },
    link: {
      all: [
        "Mojito",
        "Paloma",
        "Tequila Sunrise",
        "Tequila Punch",
        "Margarita Blue",
        "Bloody Mary",
      ],
      current: "Margarita Blue",
      image: "",
    },
  },
  {
    description: {
      title: "Bloody Mary",
      content: "",
    },
    ingredients: [
      "2 oz Puerto Bello Blanco",
      "4 oz Tomato Juice",
      "1/2 oz Lime Juice",
      "4 drops Worcestershire Sauce",
      "2 drops Hot Sauce",
      "1 pinch Celery Salt",
      "1 pinch Black Pepper",
      "Garnish: Celery, Lime Slice & Jalapeño Slices"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "Mix Puerto Bello Blanco, tomato juice, lime juice, worcestershire sauce, hot sauce, celery, salt and black pepper to a shaker and fill with ice.",
      },
      {
        title: "Step 02",
        content: "Shake briefly and strain into a pint glass filled with fresh ice.",
      },
      {
        title: "Step 03",
        content: "Garnish with a Lime Slice, Celery and Jalapeño Slices.",
      }
    ],
    image: {
      src: "/images/BloodyM.jpg",
      alt: "Bloody Mary Image",
    },
    link: {
      all: [
        "Mojito",
        "Paloma",
        "Tequila Sunrise",
        "Tequila Punch",
        "Margarita Blue",
        "Bloody Mary",
      ],
      current: "Bloody Mary",
      image: "",
    },
  },
];

export const MixesWithReposado = [
  {
    description: {
      title: "Classic Puerto Bello Margarita",
      content: "",
    },
    ingredients: [
      "1-1/2 oz Puerto Bello Reposado",
      "1 oz Lime Juice",
      "1/2 oz Agave Syrup",
      "Salt",
      "1 Handful Fresh Cilantro",
      "1 Lime Slice"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "Frost the glass with lime juice and salt.",
      },
      {
        title: "Step 02",
        content: "Add Puerto Bello Reposado, lime juice, agave syrup and crushed ice into the shaker.",
      },
      {
        title: "Step 03",
        content:
          "Shake properly and strain into the glass.",
      },
      {
        title: "Step 04",
        content: "Decorate with a handful fresh cilantro and a lime slice!",
      },
    ],
    image: {
      src: "/images/classicM.jpg",
      alt: "A Classic Margarita Image",
    },
    link: {
      all: ["Margarita", "Mango Frozen Margarita", "Mint Tequila", "Tequila Piña"],
      current: "Margarita",
      image: "",
    },
  },
  {
    description: {
      title: "Mango Frozen Margarita",
      content: "",
    },
    ingredients: [
      "1-1/2 oz Puerto Bello Reposado",
      "1/2 oz Lime Juice",
      "Crushed Ice",
      "1 oz Blended Mango",
      "Sugar & Salt"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "Run a lime wedge around the upper outer rim of your glasses, then dip the rim in a chili powder/salt mixture and twist to coat.",
      },
      {
        title: "Step 02",
        content: "Add frozen mangoes, freshly squeezed lime juice, Puerto Bello Reposado and crushed ice into a blender.",
      },
      {
        title: "Step 03",
        content:
          "Blend until slushy. ",
      },
      {
        title: "Step 04",
        content: "Pour it into the prepared glasses, garnish with mango and strawberry wedges and enjoy!",
      },
    ],
    image: {
      src: "/images/MangoFrozen.jpg",
      alt: "MangoFrozen Image",
    },
    link: {
      all: ["Margarita", "Mango Frozen Margarita", "Mint Tequila", "Tequila Piña"],
      current: "Mango Frozen Margarita",
      image: "",
    },
  },
  {
    description: {
      title: "Mint Tequila",
      content: "",
    },
    ingredients: [
      "3/4 oz Puerto Bello Reposado",
      "1/4 oz Lime Juice",
      "1/2 oz Agave Syrup",
      "6 Mint Leaves"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "Pour Puerto Bello Reposado, lime juice and agave syrup into a shaker with ice.",
      },
      {
        title: "Step 02",
        content: "Shake and strain into a tall glass filled with ice.",
      },
      {
        title: "Step 03",
        content:
          "Garnish with mint leaves and lime slices.",
      },
    ],
    image: {
      src: "/images/MintTequila.jpg",
      alt: "Mint Tequila Image",
    },
    link: {
      all: ["Margarita", "Mango Frozen Margarita", "Mint Tequila", "Tequila Piña"],
      current: "Mint Tequila",
      image: "",
    },
  },
  {
    description: {
      title: "Tequila Piña",
      content: "",
    },
    ingredients: [
      "2-1/4 oz Puerto Bello Reposado",
      "2-1/2 oz Pineapple Juice",
      "2-1/2 oz Orange Juice",
      "Sugar"
    ],
    recipe: [
      {
        title: "Step 01",
        content: "Take a Pineapple and cut a bit more than the head leaving it open like a pitcher or punch bowl.",
      },
      {
        title: "Step 02",
        content: "Pour Puerto Bello Reposado, pineapple juice and orange juice into it.",
      },
      {
        title: "Step 03",
        content:
          "Add a pinch of sugar and gently give it a single stir without damaging the pineapple.",
      },
      {
        title: "Step 04",
        content: "Drink up!",
      },
    ],
    image: {
      src: "/images/Tequila-pina.jpg",
      alt: "Tequila Piña Image",
    },
    link: {
      all: ["Margarita", "Mango Frozen Margarita", "Mint Tequila", "Tequila Piña"],
      current: "Tequila Piña",
      image: "",
    },
  },
];