import React, { useState, useEffect } from "react";
import { Mask, Footer, Nav, Menu } from "@components";
import AOS from "aos";
import "aos/dist/aos.css";

const bottles = [
	{
		name: 'Blanco',
		title: 'Puerto Bello\nBlanco',
		description: 'A fresh taste of agave.',
		color: '#4cc1bb',
		image: {
			src: '/images/Grupo 810.png',
			alt: 'Puerto bello Grupo 810'
		},
		imageOfSelect: {
			src: '/images/blanco 2.png',
			alt: 'Botella de tequila blanco',
			className: 'mr-auto -ml-10'
		},
		meetLink: 'puerto-bello-blanco',
		mobileMeetLink: 'https://jensensliquors.com/puerto-bello-blanco-tequila.html'
	},
	{
		name: 'Reposado',
		title: 'Puerto Bello\nReposado',
		description: 'Sweet delicacy.',
		color: '#e8aa4c',
		image: {
			src: '/images/Grupo 796.png',
			alt: 'Puerto bello reposado'
		},
		imageOfSelect: {
			src: '/images/Botella Reposado Vector.png',
			alt: 'Botella de tequila reposado',
			className: 'mx-auto col-start-2'
		},
		meetLink: 'puerto-bello-reposado',
		mobileMeetLink: 'https://www.checkersliquors.com/product/puerto-bello-tequila-reposado-750ml/',
	},
	{
		name: 'Añejo',
		title: 'Puerto Bello\nAñejo',
		description: 'Jewel of the crown. Pure sense of wood.',
		color: '#2d9870',
		image: {
			src: '/images/Grupo 798.png',
			alt: 'Puerto bello Añejo'
		},
		imageOfSelect: {
			src: '/images/Botella Reposado Añejo.png',
			alt: 'Botella de tequila añejo',
			className: 'col-start-3 ml-auto -mr-10'
		},
		meetLink: 'puerto-bello-añejo',
		mobileMeetLink: 'https://www.checkersliquors.com/product/puerto-bello-tequila-anejo-750ml/',
	}
];

const InteractiveSection = ({ product, setProduct}) =>  {

  return (
    <div className="sm:inline-block interactive-section w-full h-auto bg-soft-white relative float-left seccionInteractiva">
      <div className="relative w-full h-auto float-left">
        <img src="/images/Enmascarar downchain blue.png" className='relative float-left w-full h-auto' alt="downchain blue" />
        <span className="absolute bottom-0 left-0 w-full h-full radial-gradient-white"></span>
      </div>
      <div className="relative float-left grid grid-cols-2 -mt-20 2xl:-mt-48 px-32 2xl:px-60">
        <div className="flex flex-col justify-evenly">
          <div>
            <h3 className="text-[#231F20] text-xl leading-[48px] xl:leading-[56px] xl:text-2xl 2xl:mr-60 font-mainsailslab font-normal mb-[27px] xl:mb-20 2xl:mb-12 uppercase tituloOurTequila">
              { product.title }
            </h3>
            <p className="text-[21px] leading-[25px] xl:text-xl xl:leading-[46px] font-turquoise font-normal mb-[56px] xl:mb-20 descripcionOurTequila" style={{ color: product.color }}>
              { product.description }
            </p>
          </div>
          <a alt={ `Puerto bello ${ product.name.toLowerCase() }` } 
            href={ `/our-tequila/${ product.meetLink }` }
    className="px-[25px] py-[10px] xl:px-12 xl:py-4 mr-auto text-[16px] xl:text-lg tracking-[3.2px] xl:tracking-[6px] text-white font-bold uppercase mb-20 leading-[40px] xl:leading-[40px] hidden md:inline-block botonMeetMeOurTequila" style={{ backgroundColor: product.color }}>
            Meet Me
          </a>
          <a alt={ `Puerto bello ${ product.name.toLowerCase() }` } 
            href={ product.mobileMeetLink }
    className="px-[25px] py-[10px] xl:px-12 xl:py-4 mr-auto text-[16px] xl:text-lg tracking-[3.2px] xl:tracking-[6px] text-white font-bold uppercase mb-20 leading-[40px] xl:leading-[40px] md:hidden" style={{ backgroundColor: product.color }}>
            Meet Me
          </a>
          <div className="relative grid grid-cols-3 mb-20">
            <h4
              className={["cursor-pointer font-turquoise pl-4 text-base 2xl:text-xl", product.name !== "Blanco" && "text-[#80D8D0]"].join(
                " "
              )}
              onClick={() => {setProduct(bottles[0])}}
            >
              Blanco
            </h4>
            <h4
              className={[
                "cursor-pointer font-turquoise text-base 2xl:text-xl text-center",
                product.name !== "Reposado" && "text-[#80D8D0]",
              ].join(" ")}
              onClick={() => {setProduct(bottles[1])}}
            >
              Reposado
            </h4>
            <h4
              className={[
                "cursor-pointer font-turquoise pr-4 text-base 2xl:text-xl text-right",
                product.name !== "Añejo" && "text-[#80D8D0]",
              ].join(" ")}
              onClick={() => {setProduct(bottles[2])}}
            >
              Añejo
            </h4>
            <div className="absolute bottom-1/3 w-11/12 left-0 right-0 h-0.5 col-span-3 bg-[#94D6D0] mx-auto "></div>
            <img
              src={product.imageOfSelect.src}
              alt={product.imageOfSelect.alt}
              className={product.imageOfSelect.className}
              style={{transition:'all 0.5s ease 0.5s'}}
            />
          </div>
        </div>
        <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
          <img src={ product.image.src } alt={ product.image.alt } className="mx-auto imageOurTequila" />
        </div>
      </div>
    </div>
  )
}


const SeccionProductos = ({ product, setProduct}) =>  {

  return (
    <div className="seccionProductos ">
      <div className="sm:inline-block interactive-section w-full h-auto bg-soft-white relative float-left producto">
        
        <div className="relative float-left grid grid-cols-2 -mt-20 2xl:-mt-48 px-32 2xl:px-60" style={{display:'flex', flexDirection:'column'}}>
          <div className="flex flex-col justify-evenly">
            <div>
              <h3 className="text-[#231F20] text-xl leading-[48px] xl:leading-[56px] xl:text-2xl 2xl:mr-60 font-mainsailslab font-normal mb-[27px] xl:mb-20 2xl:mb-12 uppercase tituloOurTequila">
                Puerto Bello Blanco
              </h3>
              <p className="text-[21px] leading-[25px] xl:text-xl xl:leading-[46px] font-turquoise font-normal mb-[56px] xl:mb-20 descripcionOurTequila" style={{ color: '#4cc1bb' }}>
                A fresh taste of agave.
              </p>
            </div>
            <div>
              <img src="/images/Grupo 810.png" alt="Puerto Bello Blanco" className="mx-auto imageOurTequila" />
            </div>
            <a alt='Puerto Bello Blanco'
              href='/our-tequila/puerto-bello-blanco'
              className="px-[25px] py-[10px] xl:px-12 xl:py-4 mr-auto text-[16px] xl:text-lg tracking-[3.2px] xl:tracking-[6px] text-white font-bold uppercase mb-20 leading-[40px] xl:leading-[40px] hidden md:inline-block" style={{ backgroundColor: '#4cc1bb' }}>
              Meet Me
            </a>
            <a alt='puerto-bello-blanco' 
              href='/our-tequila/puerto-bello-blanco'
              className="px-[25px] py-[10px] xl:px-12 xl:py-4 mr-auto text-[16px] xl:text-lg tracking-[3.2px] xl:tracking-[6px] text-white font-bold uppercase xs:mb-12 mb-20 leading-[40px] xl:leading-[40px] md:hidden botonMeetMeOurTequila" style={{ backgroundColor:'#4cc1bb' }}>
              Meet 
            </a>
          </div>
          
        </div>
      </div>

      <div className="sm:inline-block interactive-section w-full h-auto relative float-left producto bg-soft-white">
        
        <div className="relative float-left grid grid-cols-2 -mt-20 2xl:-mt-48 px-32 2xl:px-60" style={{display:'flex', flexDirection:'column'}}>
          <div className="flex flex-col justify-evenly">
            <div>
              <h3 className="text-[#231F20] text-xl leading-[48px] xl:leading-[56px] xl:text-2xl 2xl:mr-60 font-mainsailslab font-normal mb-[27px] xl:mb-20 2xl:mb-12 uppercase tituloOurTequila">
                Puerto Bello Reposado
              </h3>
              <p className="text-[21px] leading-[25px] xl:text-xl xl:leading-[46px] font-turquoise font-normal mb-[56px] xl:mb-20 descripcionOurTequila" style={{ color: '#e8aa4c' }}>
                Sweet delicacy.
              </p>
            </div>
            <div>
              <img src="/images/Grupo 796.png" alt="Puerto Bello Blanco" className="mx-auto imageOurTequila" />
            </div>
            <a alt='Puerto Bello Blanco'
              href='/our-tequila/puerto-bello-reposado'
              className="px-[25px] py-[10px] xl:px-12 xl:py-4 mr-auto text-[16px] xl:text-lg tracking-[3.2px] xl:tracking-[6px] text-white font-bold uppercase mb-20 leading-[40px] xl:leading-[40px] hidden md:inline-block" style={{ backgroundColor: '#e8aa4c' }}>
              Meet Me
            </a>
            <a alt='puerto-bello-reposado' 
              href='/our-tequila/puerto-bello-reposado'
              className="px-[25px] py-[10px] xl:px-12 xl:py-4 mr-auto text-[16px] xl:text-lg tracking-[3.2px] xl:tracking-[6px] text-white font-bold uppercase xs:mb-12 mb-20 leading-[40px] xl:leading-[40px] md:hidden botonMeetMeOurTequila" style={{ backgroundColor:'#e8aa4c' }}>
              Meet Me
            </a>
          </div>
          
        </div>
      </div>


      <div className="sm:inline-block interactive-section w-full h-auto  relative float-left producto bg-soft-white">
        
        <div className="relative float-left grid grid-cols-2 -mt-20 2xl:-mt-48 px-32 2xl:px-60" style={{display:'flex', flexDirection:'column'}}>
          <div className="flex flex-col justify-evenly">
            <div>
              <h3 className="text-[#231F20] text-xl leading-[48px] xl:leading-[56px] xl:text-2xl 2xl:mr-60 font-mainsailslab font-normal mb-[27px] xl:mb-20 2xl:mb-12 uppercase tituloOurTequila">
                Puerto Bello Añejo
              </h3>
              <p className="text-[21px] leading-[25px] xl:text-xl xl:leading-[46px] font-turquoise font-normal mb-[56px] xl:mb-20 descripcionOurTequila" style={{ color: '#2d9870' }}>
                Jewel of the crown. Pure sense of wood..
              </p>
            </div>
            <div>
              <img src="/images/Grupo 798.png" alt="Puerto Bello Añejo" className="mx-auto imageOurTequila" />
            </div>
            <a alt='Puerto Bello Blanco'
              href='/our-tequila/puerto-bello-añejo'
              className="px-[25px] py-[10px] xl:px-12 xl:py-4 mr-auto text-[16px] xl:text-lg tracking-[3.2px] xl:tracking-[6px] text-white font-bold uppercase mb-20 leading-[40px] xl:leading-[40px] hidden md:inline-block" style={{ backgroundColor: '#2d9870' }}>
              Meet Me
            </a>
            <a alt='puerto-bello-añejo' 
              href='/our-tequila/puerto-bello-añejo'
              className="px-[25px] py-[10px] xl:px-12 xl:py-4 mr-auto text-[16px] xl:text-lg tracking-[3.2px] xl:tracking-[6px] text-white font-bold uppercase xs:mb-12 mb-20 leading-[40px] xl:leading-[40px] md:hidden botonMeetMeOurTequila" style={{ backgroundColor:'#2d9870' }}>
              Meet Me
            </a>
          </div>
          
        </div>
      </div>

    </div>
  )
}

const ImageSection = () => (
  <div> 
    <img src="/images/pexels-jill-burrow.png" className='bg-bottom' alt='Pexels' />
  </div>
)

const OurTequilaHeadSection = () => (
  <div className="relative flex flex-col justify-center our-tequila bg-our-tequila align-center text-white xl:min-height-[40vw]  sm:py-[100px] py-[200px] min-height-0 ourTequilaHeader">
	<Mask color='bisque' />
    <div className="mx-auto w-1/3 text-center mt-6 mainsail-calm text-[32px] leading-[50px] sm:text-2xl 2xl:text-hero" data-aos="fade-in" data-aos-duration="2000">Our<br/>Tequilas</div>
  </div>
);


const ParalaxSection = () => (
  <div className="paralaxSectionOurTequila">
    
  </div>
);

const OurTequila = function() {
	const [product, setProduct] = useState(bottles[0]),
		[menuOpen, setMenuOpen] = useState(false);

	function handleProduct(value) {
		setProduct(value)
	}

	function handleMenuToggle() {
		setMenuOpen(menuOpen ? false : true)
	}

  useEffect(() => {
    document.title = "Tequila Puerto Bello - Our Tequilas";
    AOS.init({
      // duration : 5000
    });
  }, []);

	return(
		<div className={ ["bg-orange w-screen h-screen", menuOpen ? 'overflow-hidden' : 'overflow-auto'].join(' ') }>
			<Nav onMenu={ () => handleMenuToggle() } color="text-white"/>
			<OurTequilaHeadSection />
			<InteractiveSection product={ product } setProduct={ handleProduct } className="seccionInteractiva" />
      <SeccionProductos className="seccionProductos"/>
      <ParalaxSection />
			<Footer />
			<Menu visible={ menuOpen } onClose={ () => handleMenuToggle() } />
		</div>
	);
}

export default OurTequila;
