import React from "react";

const Mask = ({ color = "blue", className = "" }) => {
  const mask = {
    blue: "bg-mask-blue",
    "soft-blue": "bg-mask-soft-blue",
    bisque: "bg-mask-bisque",
    green: "bg-mask-green",
    "break-blue": "bg-mask-break-blue",
  };
  return (
    <div
      className={[
        "absolute z-0 w-full h-full bg-no-repeat pointer-events-none",
        className,
        color.startsWith("break") ? "inset-0  bg-full" : "bg-right-bottom bg-contain",
        mask[color],
      ].join(" ")}
    ></div>
  );
};

export default Mask;
