import React from "react";
import ReactDOM from "react-dom";
import App from "@/App";
import "@/index.css";
import "@/fonts/MainSail/MainsailCPC-Calm.otf";
import "@/fonts/MainSail/MainsailCPC-Windy.otf";
import "@/fonts/MainSail/MainsailSlabCPC-Calm.otf";
import "@/fonts/MainSail/MainsailSlabCPC-Windy.otf";

ReactDOM.render(<App />, document.getElementById("root"));
