import React from "react";

const Nav = ({ color='', onMenu, burgerColor = '#FFFFFF' }) => {
  const isClass = color.indexOf('text') !== -1;
  return (
    <div
      className={[
        "relative flex xl:justify-around items-center justify-center w-full pt-[23px] sm:pt-20 mx-auto text-base header max-w-screen-2xl turquoise",
        color && color,
      ].join(" ")}
      style = {{ color: !isClass && color, zIndex:100 }}
    >
      <a className="xl:visible xl:visible hidden sm:inline-block flex-1 invisible text-center font-athelas" href="/process">Process</a>
      <a className="xl:visible xl:visible hidden sm:inline-block flex-1 invisible text-center font-athelas" href="/our-tequila">Our Tequilas</a>
      <a className="sm:flex-1 text-center w-auto tituloheaderMovil" href="/">
        <div className="flex-1 font-bold font-athelas tracking-widest leading-[14px] text-center uppercase xl:text-base text-[20px] leading-[24px] sm:text-xs xl:mb-0 mb-[3px]" >
          Puerto Bello 
        </div>
        <div className="flex-1 text-[12px] leading-[14px] font-athelas xl:text-sm sm:text-[8px] sm:leading-[9px] text-center uppercase">Tequila</div>
      </a>
      <a className="xl:visible xl:visible hidden sm:inline-block flex-1 invisible text-center font-athelas" href="/cocktail">Cocktails</a>
      <a className="xl:visible xl:visible hidden sm:inline-block flex-1 invisible text-center font-athelas" href="/contact-us">Contact Us</a>
	  <button onClick={ typeof onMenu === 'function' ? () => onMenu() : null } className='w-[23.59px] h-[23.59px] sm:h-[26px] sm:w-[26px] absolute sm:top-20 right-[31.5px] top-[30px] sm:right-[5rem] flex-1 align-center xl:invisible xl:hidden'>
		  <div className='w-full h-[3px] rounded-[1.5px] sm:rounded-0 sm:h-[2px] mb-[6px]' style={{ backgroundColor: burgerColor}}></div>
		  <div className='w-full h-[3px] rounded-[1.5px] sm:rounded-0 sm:h-[2px] mb-[6px]' style={{ backgroundColor: burgerColor}}></div>
		  <div className='w-full h-[3px] rounded-[1.5px] sm:rounded-0 sm:h-[2px]' style={{ backgroundColor: burgerColor}}></div>
	  </button>
    </div>
  );
};

export default Nav;
